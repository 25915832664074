export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_ACTIVE_LINE_CHART_TAB = "SET_ACTIVE_LINE_CHART_TAB";
export const SET_ACTIVE_TABLE = "SET_ACTIVE_TABLE";

export const setActiveTab = (tabKey) => ({
  type: SET_ACTIVE_TAB,
  payload: tabKey,
});

export const setActiveLineTab = (tabKey) => ({
  type: SET_ACTIVE_LINE_CHART_TAB,
  payload: tabKey,
});

export const setActiveTable = (tabKey) => ({
  type: SET_ACTIVE_TABLE,
  payload: tabKey,
});
