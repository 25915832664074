import React, { useEffect, useState, useCallback, useMemo } from "react";
import SplineBar from "../Highcharts/SplineBar";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getTrendsData } from "../../Redux/Actions/Thunk/TrendThunk";
import { formDataAppend, numberWithCommas } from "../../Utils/helpers";
import { Spin, Switch } from "antd";
import { format_data } from "../../Utils/helpers";
import { Tooltip, Tabs } from "antd";
import { FaInfoCircle } from "react-icons/fa";
import { ContactsOutlined } from "@ant-design/icons";
import HtmlParser from "html-react-parser";
import { setActiveTab } from "../../Redux/Actions/tabAction";

const Trends = React.memo(
  (props) => {
    // for organic search report only
    let accountId = props?.account_id;
    let eparams = props?.all_input_data;
    let f_date = eparams?.f_month;
    let t_date = eparams?.t_month;
    let f_date_compare = eparams?.c_f_month;
    let t_date_compare = eparams?.c_t_month;
    let device = eparams?.device_key;
    let date_range_option = eparams?.date_range_option;
    let date_range_compare_to = eparams?.date_range_compareto;
    let source_medium = eparams?.source_medium;
    //end for organic search report only

    const [globalSwitchKey, setGlobalSwitchKey] = useState(true);
    const handleToggleSwitch = useCallback(() => {
      setGlobalSwitchKey((prevState) => !prevState);
    }, []);
    const {
      trend: {
        trend: {
          [props?.block?.block_id]: {
            data: trendData = [],
            json_data: jsonData = [],
            table_data: table_data = [],
            labels: labels = [],
            y_axis = {},
            metrics: mertics_data = [],
            tab_data: dataTab = [],
            table_status: tableStatus = [],
            trend_insights: trendInsights = [],
            mom_yoy_header: mom_yoy_header = "yes",
            subscribed_accounts: subscribedAccounts = [],
            labels_category: labelCategory = [],
          } = {},
        },
        isLoading,
      } = {},
    } = useSelector((state) => state);
    const activeTabData = useSelector((state) => state.tabStack.activeTab);

    const dispatch = useDispatch();
    useEffect(() => {
      var data = props?.all_input_data;
      if (props?.length === 0) {
        var formData = formDataAppend(data);
        dispatch(getTrendsData(formData, props));
      }
    }, []);

    let periodLabel1 = labels[10] != undefined ? labels[10] : "";
    let periodLabel2 = labels[11] != undefined ? labels[11] : "";
    let periodLabel3 = labels[12] != undefined ? labels[12] : "";
    let periodLabel12 = labels[0] != undefined ? labels[0] : "";

    const rowsToHide = useMemo(() => mertics_data, [mertics_data]);
    const DataTab = useMemo(() => dataTab, [dataTab]);

    const { TabPane } = Tabs;
    //  const [activeTab, setActiveTab] = useState("Monthly");

    const handleTabChange = useCallback(
      (key) => {
        dispatch(setActiveTab(key));
        var data = props?.all_input_data;
        Object.assign(data, { view_mode: key });
        var formData = formDataAppend(data);
        dispatch(getTrendsData(formData, props));
      },
      [dispatch, props]
    );

    const tabData = DataTab;

    function DynamicTabs() {
      return (
        <div>
          <Tabs defaultActiveKey={activeTabData} onChange={handleTabChange}>
            {tabData.map((tab) => (
              <TabPane tab={tab.title} key={tab.key}>
                {/* {tab.content} */}
                {/* <th>{periodLabel12}</th>
                  <th>YoY % Change</th> */}
              </TabPane>
            ))}
          </Tabs>
        </div>
      );
    }

    /*for filter data*/
    const [filter, setFilter] = useState("");
    const { filterData } = props;

    useEffect(() => {
      var data = props?.all_input_data;
      var formData = formDataAppend(data);
      dispatch(getTrendsData(formData, props));
    }, [filterData]);

    var dateRangeOption = props?.all_input_data?.date_range_option;
    var report_key = props?.block?.report_key;
    var api_path = props?.block?.api_path;

    const config = JSON.parse(props?.block?.config);

    return (
      <>
        <Spin spinning={isLoading}>
          {/* <div className="report-v2-panel-body"> */}
          {/* dynamic tabs added */}
          {dateRangeOption != undefined &&
          dateRangeOption != "Last Month" &&
          report_key.split("_").pop() == "performance" &&
          api_path != "v2/asc_performance/overall_performance" ? (
            <p
              style={{
                fontWeight: "600",
                fontSize: "13px",
                marginBottom: "15px",
              }}
            >
              The Trends section displays MTD data for all months, based on the
              selected date range (reference the Help section for more details)
            </p>
          ) : (
            ""
          )}
          {tabData.length > 0 && tabData ? <DynamicTabs /> : ""}
          {/* chart  added */}
          <SplineBar
            jsonData={jsonData}
            labels={labels}
            y_axis={y_axis}
            title={config?.titleText}
            tooltip={config?.tooltip}
          />
          {/* hide and show mertics in table row */}
          {rowsToHide.length > 0 && rowsToHide ? (
            <div className="switch-parent-trend">
              <div className="switch-child-text">Show Secondary Metrics</div>
              <div className="switch-child-button">
                <Switch size="small" onChange={handleToggleSwitch} />
              </div>
            </div>
          ) : (
            ""
          )}
          {/* show table according to state*/}
          {tableStatus !== false &&
          activeTabData === "Weekly" &&
          tabData.length > 0 ? (
            <div className="trends-datatable">
              <table id="trends-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>{periodLabel1}</th>
                    <th>{periodLabel2}</th>
                    <th>{periodLabel3}</th>
                    <th>WOW % Change</th>
                  </tr>
                </thead>
                <tbody>
                  {table_data.map((item, index) => {
                    const { value, tooltip, data, format, roundoff, key } =
                      item;
                    if (globalSwitchKey && rowsToHide.includes(value)) {
                      return null;
                    }
                    return (
                      <tr key={index}>
                        <td>
                          {value}
                          {tooltip && (
                            <Tooltip color="black" title={tooltip}>
                              <span style={{ color: "#0e0e0b" }}>
                                <FaInfoCircle />
                              </span>
                            </Tooltip>
                          )}
                        </td>
                        {data.map((item2, j) => (
                          <td key={j}>
                            {format === "amount"
                              ? format_data(
                                  item2,
                                  format,
                                  roundoff,
                                  key,
                                  j == 3
                                )
                              : format === "percentage"
                              ? format_data(
                                  item2,
                                  format,
                                  roundoff,
                                  key,
                                  j == 3
                                )
                              : format_data(
                                  item2,
                                  format,
                                  roundoff,
                                  key,
                                  j == 3
                                )}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="trends-datatable">
              <table id="trends-table">
                {tableStatus !== false ? (
                  <thead>
                    {mom_yoy_header == "yes" ? (
                      <tr>
                        <th></th>
                        <th>{periodLabel1}</th>
                        <th>{periodLabel2}</th>
                        <th>{periodLabel3}</th>
                        <th>MoM % Change</th>
                        <th>{periodLabel12}</th>
                        <th>YoY % Change</th>
                      </tr>
                    ) : (
                      <tr>
                        <th></th>
                        {labelCategory?.map((label, index) => {
                          return <th key={index}>{label}</th>;
                        })}
                      </tr>
                    )}
                  </thead>
                ) : (
                  ""
                )}

                <tbody>
                  {table_data.map((item, index) => {
                    const { value, tooltip, data, format, roundoff, key } =
                      item;
                    if (globalSwitchKey && rowsToHide.includes(value)) {
                      return null;
                    }
                    return (
                      <tr key={index}>
                        <td>
                          {/* for for organic search report only */}
                          {value == "Brand Impressions" ? (
                            <a
                              href={
                                "/reports/v2/view/organic_search_brand_vs_nonbrand_query_performance/" +
                                accountId +
                                "?f_month=" +
                                f_date +
                                "&t_month=" +
                                t_date +
                                "&date_range_option=" +
                                date_range_option +
                                "&date_range_compareto=" +
                                date_range_compare_to +
                                "&c_f_month=" +
                                f_date_compare +
                                "&c_t_month=" +
                                t_date_compare +
                                "&cvr_calculation=Search Console Estimated Conversion Rate"
                              }
                              target="_blank"
                              style={{ textDecoration: "underline" }}
                            >
                              {value}
                            </a>
                          ) : (
                            value
                          )}

                          {/* end for organic search report only */}
                          {tooltip && (
                            <Tooltip color="black" title={tooltip}>
                              <span style={{ color: "#0e0e0b" }}>
                                <FaInfoCircle />
                              </span>
                            </Tooltip>
                          )}
                        </td>
                        {data.map((item2, j) => (
                          <td key={j}>
                            {format === "amount"
                              ? format_data(
                                  item2,
                                  format,
                                  roundoff,
                                  key,
                                  j && j === 3 && mom_yoy_header == "yes"
                                    ? true
                                    : null,
                                  j && j === 5 && mom_yoy_header == "yes"
                                    ? true
                                    : null
                                )
                              : format === "percentage"
                              ? format_data(
                                  item2,
                                  format,
                                  roundoff,
                                  key,
                                  j && j === 3 && mom_yoy_header == "yes"
                                    ? true
                                    : null,
                                  j && j === 5 && mom_yoy_header == "yes"
                                    ? true
                                    : null
                                )
                              : format === "number"
                              ? format_data(
                                  item2,
                                  format,
                                  roundoff,
                                  key,
                                  j && j === 3 && mom_yoy_header == "yes"
                                    ? true
                                    : null,
                                  j && j === 5 && mom_yoy_header == "yes"
                                    ? true
                                    : null
                                )
                              : format_data(item2)}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {trendInsights && trendInsights.length > 0 ? (
            <div className="insights">
              <h4>Insights</h4>
              <ul>
                {trendInsights.map((insight) => (
                  <li>{HtmlParser(insight)}</li>
                ))}
              </ul>
            </div>
          ) : (
            ""
          )}
          {/* </div> */}
          {/* for parent_child_accounts_rollup */}
          {subscribedAccounts && subscribedAccounts.length > 0 ? (
            <div style={{ marginTop: "3%" }}>
              Accounts Represented:
              <span>
                {" "}
                {subscribedAccounts.map((acnts, index) => (
                  <span key={acnts.account_id}>
                    <a
                      href={
                        "/reports/v2/view/holistic_performance/" +
                        acnts?.salesforce_id +
                        "?f_month=" +
                        f_date +
                        "&t_month=" +
                        t_date +
                        "&date_range_option=" +
                        date_range_option +
                        "&date_range_compareto=" +
                        date_range_compare_to +
                        "&c_f_month=" +
                        f_date_compare +
                        "&c_t_month=" +
                        t_date_compare
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {acnts.account_name}
                    </a>
                    {index < subscribedAccounts.length - 1 && ", "}
                  </span>
                ))}
              </span>
            </div>
          ) : (
            ""
          )}
        </Spin>
      </>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.activeTabData === nextProps.activeTabData;
  }
);

export default Trends;
