import { useState, useRef } from "react";
import { DatePicker, Select, Switch, Form } from "antd";
import moment, { months } from "moment";
import {
  capitalizeFirstLetter,
  toLowerCaseFirstLetter,
} from "../../Utils/helpers";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDefaults } from "../../Redux/Actions/inputControlAction";

const { Option } = Select;

const InputRangePicker = (props) => {
  const buttonRef = useRef(null);
  const selectRef = useRef(null);
  const [open, setOpen] = useState();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [showAdditionalRanges, setShowAdditionalRanges] = useState(true);
  const [dateVal, setDateVal] = useState("");
  const [rangeVal, setRangeVal] = useState("");
  const pickerType = props?.block?.mode_type;
  const presetRanges = props?.block?.presetRanges;
  const additionalPresetRanges = props?.block?.additionalPresetRanges;

  const dateFormat = props?.block?.format;
  const hideToggleSwitch = props?.block?.hideToggleSwitch;
  const month_range = props?.block?.month_range;
  const compare_with = props?.block?.compare_with;
  const hideText = props?.block?.hideText;
  const showAdditionalOptions = props?.block?.showAdditionalOptions;
  const preset_range_list = props?.block?.preset_range_list;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params_data = Object.fromEntries(urlSearchParams.entries());
  const component_type = props?.block?.component_type;

  const storedArray = localStorage.getItem("datePersistVal");
  const datePersistVal = JSON.parse(storedArray);
  if (props?.block.hasOwnProperty("subDays")) {
    var subDays = parseInt(props?.block?.subDays);
  } else {
    var subDays = "";
  }

  var defaultRangeOption =
    props?.block?.default_date_range_option != undefined
      ? props?.block?.default_date_range_option
      : "Last Month";
  var [defaultCRangeOption, setDefaultCRangeOption] = useState(
    props?.block?.default_compare_date_range_option != undefined
      ? props?.block?.default_compare_date_range_option
      : "Same Period Last Year"
  );

  var presetRangesList = {};

  if (pickerType == "month") {
    var customFormat = "MMM, YYYY";
  } else {
    var customFormat = "MMM DD, YYYY";
  }

  if (presetRanges != undefined && presetRanges.includes("Yesterday")) {
    if (subDays != "") {
      presetRangesList.yesterday = [
        moment().subtract(subDays, "day"),
        moment().subtract(subDays, "day"),
      ];
    } else {
      presetRangesList.yesterday = [
        moment().subtract(1, "day"),
        moment().subtract(1, "day"),
      ];
    }
  }

  if (presetRanges != undefined && presetRanges.includes("Today")) {
    presetRangesList.today = [moment(), moment()];
  }

  if (presetRanges != undefined && presetRanges.includes("Last 7 Days")) {
    if (subDays != "") {
      presetRangesList.last_7_days = [
        moment().subtract(6 + subDays, "days"),
        moment().subtract(subDays, "day"),
      ];
    } else {
      presetRangesList.last_7_days = [
        moment().subtract(7, "days"),
        moment().subtract(1, "day"),
      ];
    }
  }

  if (presetRanges != undefined && presetRanges.includes("Last 30 Days")) {
    if (subDays != "") {
      presetRangesList.last_30_days = [
        moment().subtract(29 + subDays, "days"),
        moment().subtract(subDays, "day"),
      ];
    } else {
      presetRangesList.last_30_days = [
        moment().subtract(30, "days"),
        moment().subtract(1, "day"),
      ];
    }
  }

  if (presetRanges != undefined && presetRanges.includes("Last 60 Days")) {
    presetRangesList.last_60_days = [
      moment().subtract(60, "days"),
      moment().subtract(1, "day"),
    ];
  }

  if (presetRanges != undefined && presetRanges.includes("Last 90 Days")) {
    presetRangesList.last_90_days = [
      moment().subtract(90, "days"),
      moment().subtract(1, "day"),
    ];
  }

  if (presetRanges != undefined && presetRanges.includes("Last 180 Days")) {
    presetRangesList.last_180_days = [
      moment().subtract(180, "days"),
      moment().subtract(1, "day"),
    ];
  }

  if (presetRanges != undefined && presetRanges.includes("Last 365 Days")) {
    presetRangesList.last_365_days = [
      moment().subtract(365, "days"),
      moment().subtract(1, "day"),
    ];
  }

  if (presetRanges != undefined && presetRanges.includes("Last 2 Years")) {
    presetRangesList.last_2_years = [
      moment().subtract(2, "year"),
      moment().subtract(1, "day"),
    ];
  }

  if (presetRanges != undefined && presetRanges.includes("Last 3 Years")) {
    presetRangesList.last_3_years = [
      moment().subtract(3, "year"),
      moment().subtract(1, "day"),
    ];
  }

  if (presetRanges != undefined && presetRanges.includes("This Month")) {
    if (subDays != "" && moment().format("D") === "1") {
      presetRangesList.this_month = [
        moment().subtract(subDays, "day"),
        moment().startOf("month"),
      ];
    } else if (subDays != "") {
      presetRangesList.this_month = [
        moment().startOf("month"),
        moment().subtract(subDays, "day"),
      ];
    } else if (moment().format("D") === "1") {
      presetRangesList.this_month = [moment().startOf("month"), moment()];
    } else {
      presetRangesList.this_month = [
        moment().startOf("month"),
        moment().subtract(1, "day"),
      ];
    }
  }

  if (presetRanges != undefined && presetRanges.includes("Last Month")) {
    presetRangesList.last_month = [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ];
  }

  if (presetRanges != undefined) {
    var defaultDateRange = [
      presetRangesList[defaultRangeOption][0],
      presetRangesList[defaultRangeOption][1],
    ];
  } else {
    var defaultDateRange = [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ];
  }
  if (
    presetRanges != undefined &&
    datePersistVal != null &&
    datePersistVal.hasOwnProperty("persist_label") &&
    presetRanges.includes(datePersistVal["date_range_option"]) &&
    component_type == datePersistVal["component_type"] &&
    dateFormat == datePersistVal["dateFormat"]
  ) {
    defaultRangeOption = toLowerCaseFirstLetter(
      datePersistVal["persist_label"]
    );
  }

  if (
    Object.keys(params_data) != 0 &&
    params_data.hasOwnProperty("date_range_option")
  ) {
    defaultRangeOption = toLowerCaseFirstLetter(
      params_data["date_range_option"].toLowerCase().split(" ").join("_")
    );
  }

  if (
    Object.keys(params_data) != 0 &&
    params_data.hasOwnProperty("date_range_compareto")
  ) {
    defaultCRangeOption = params_data["date_range_compareto"];
  }

  if (
    datePersistVal != null &&
    additionalPresetRanges != undefined &&
    additionalPresetRanges.includes(
      datePersistVal["additional_persist_label"]
    ) &&
    component_type == datePersistVal["component_type"]
  ) {
    defaultCRangeOption = datePersistVal["additional_persist_label"];
  }

  if (rangeVal != "") {
    defaultCRangeOption = rangeVal;
  }

  const [dateRange, setDateRange] = useState(defaultDateRange);
  var selectedVal = useRef(capitalizeFirstLetter(defaultRangeOption));
  const selectedAdditionalVal = useRef(defaultCRangeOption);

  var additionalPresetRangesList = [];

  if (
    additionalPresetRanges != undefined &&
    additionalPresetRanges.includes("Previous Period")
  ) {
    var diff = Math.round(
      (dateRange[1] - dateRange[0]) / (1000 * 60 * 60 * 24)
    );
    var previous_period = {
      label: "Previous Period",
      value: [
        moment(dateRange[0]).subtract(diff, "day"),
        moment(dateRange[0]).subtract(1, "day"),
      ],
    };
    additionalPresetRangesList.push(previous_period);
  }

  if (
    additionalPresetRanges != undefined &&
    additionalPresetRanges.includes("Same Period Last Year")
  ) {
    var same_period_obj = {
      label: "Same Period Last Year",
      value: [
        moment(dateRange[0]).subtract(1, "year"),
        moment(dateRange[1]).subtract(1, "year"),
      ],
    };

    additionalPresetRangesList.push(same_period_obj);
  }

  if (
    additionalPresetRanges != undefined &&
    additionalPresetRanges.includes("Same 30 Days Last Year")
  ) {
    var same_30_obj = {
      label: "Same 30 Days Last Year",
      value: [
        moment(dateRange[0]).subtract(1, "year"),
        moment(dateRange[1]).subtract(1, "year"),
      ],
    };
    additionalPresetRangesList.push(same_30_obj);
  }

  if (
    additionalPresetRanges != undefined &&
    additionalPresetRanges.includes("Previous Month")
  ) {
    var previous_month = {
      label: "Previous Month",
      value: [
        moment(dateRange[0]).subtract(1, "month").startOf("month"),
        moment(dateRange[0]).subtract(1, "month").endOf("month"),
      ],
    };
    additionalPresetRangesList.push(previous_month);
  }

  const [dateSRange, setSDateRange] = useState([
    moment(dateRange[0]).subtract("1", "year"),
    moment(dateRange[1]).subtract("1", "year"),
  ]);

  useEffect(() => {
    if (Object.keys(params_data) != 0) {
      if (params_data.hasOwnProperty("f_date")) {
        setDateRange([
          moment(params_data["f_date"], "YYYY-MM-DD"),
          moment(params_data["t_date"], "YYYY-MM-DD"),
        ]);
        if (
          params_data.hasOwnProperty("date_range_compareto") &&
          params_data["date_range_compareto"] == "None"
        ) {
          setShowAdditionalRanges(false);
          setDateVal("none");
          selectedAdditionalVal.current = "None";
        } else {
          setSDateRange([
            moment(params_data["c_f_date"], "YYYY-MM-DD"),
            moment(params_data["c_t_date"], "YYYY-MM-DD"),
          ]);
        }
      } else if (params_data.hasOwnProperty("f_month")) {
        setDateRange([
          moment(params_data["f_month"], dateFormat),
          moment(params_data["t_month"], dateFormat),
        ]);
        if (
          params_data.hasOwnProperty("month_range_compareto") &&
          params_data["month_range_compareto"] == "None"
        ) {
          setShowAdditionalRanges(false);
          setDateVal("none");
          selectedAdditionalVal.current = "None";
        } else {
          setSDateRange([
            moment(params_data["c_f_month"], dateFormat),
            moment(params_data["c_t_month"], dateFormat),
          ]);
        }
      }
    } else if (datePersistVal != null) {
      if (
        presetRanges != undefined &&
        datePersistVal.hasOwnProperty("date_range_option") &&
        presetRanges.includes(datePersistVal["date_range_option"]) &&
        component_type == datePersistVal["component_type"] &&
        dateFormat == datePersistVal["dateFormat"]
      ) {
        setDateRange([
          moment(datePersistVal["f_date"], "YYYY-MM-DD"),
          moment(datePersistVal["t_date"], "YYYY-MM-DD"),
        ]);
      }
      if (
        additionalPresetRanges != undefined &&
        additionalPresetRanges.includes(
          datePersistVal["additional_persist_label"]
        ) &&
        component_type == datePersistVal["component_type"] &&
        dateFormat == datePersistVal["dateFormat"]
      ) {
        setSDateRange([
          moment(datePersistVal["c_f_date"], "YYYY-MM-DD"),
          moment(datePersistVal["c_t_date"], "YYYY-MM-DD"),
        ]);
      }
      if (
        datePersistVal.hasOwnProperty("additional_persist_label") &&
        datePersistVal["additional_persist_label"] == "None" &&
        dateFormat === datePersistVal["dateFormat"]
      ) {
        setShowAdditionalRanges(false);
        setDateVal("none");
        selectedAdditionalVal.current = "None";
      }
    }
  }, [window.location.search]);

  useEffect(() => {
    if (selectedAdditionalVal.current == "Same Period Last Year") {
      setSDateRange([
        moment(dateRange[0]).subtract("1", "year"),
        moment(dateRange[1]).subtract("1", "year"),
      ]);
      additionalDateValue.current = [
        moment(dateRange[0]).subtract("1", "year"),
        moment(dateRange[1]).subtract("1", "year"),
      ];
    } else if (selectedAdditionalVal.current == "Same 30 Days Last Year") {
      setSDateRange([
        moment(dateRange[0]).subtract("1", "year"),
        moment(dateRange[1]).subtract("1", "year"),
      ]);
      additionalDateValue.current = [
        moment(dateRange[0]).subtract("1", "year"),
        moment(dateRange[1]).subtract("1", "year"),
      ];
    } else if (selectedAdditionalVal.current == "Previous Month") {
      setSDateRange([
        moment(dateRange[0]).subtract(1, "month").startOf("month"),
        moment(dateRange[0]).subtract(1, "month").endOf("month"),
      ]);
      additionalDateValue.current = [
        moment(dateRange[0]).subtract(1, "month").startOf("month"),
        moment(dateRange[0]).subtract(1, "month").endOf("month"),
      ];
    } else if (selectedAdditionalVal.current == "Previous Period") {
      var diff = Math.round(
        (dateRange[1] - dateRange[0]) / (1000 * 60 * 60 * 24)
      );
      setSDateRange([
        moment(dateRange[0]).subtract(diff, "day"),
        moment(dateRange[0]).subtract(1, "day"),
      ]);
      additionalDateValue.current = [
        moment(dateRange[0]).subtract(diff, "day"),
        moment(dateRange[0]).subtract(1, "day"),
      ];
    } else {
      // setSDateRange([moment(dateRange[0]), moment(dateRange[0])]);
    }
  }, [dateRange, selectedVal]);

  const dateValue = useRef(null);
  const additionalDateValue = useRef(null);

  const handlePresetRangeSelect = (value) => {
    setDateRange(presetRangesList[value]);
    dateValue.current = presetRangesList[value];

    selectedVal.current = value;
  };
  const handleCalendarOpenChange = (open) => {
    if (!open) {
      setCalendarOpen(open);
    }
    setOpen();
  };

  const handleToggleCalendar = () => {
    setCalendarOpen(!calendarOpen);
    setOpen(true);
    // dateValue.current =
    // selectedVal.current = "Custom";
  };
  useEffect(() => {
    if (calendarOpen) {
      const buttonPosition = buttonRef.current.getBoundingClientRect();
      const dropdown = document.getElementById("mainCalendarDropdown");

      const top = buttonPosition.top + buttonPosition.height;
      const left = buttonPosition.left;

      dropdown.style.position = "absolute";
      dropdown.style.top = `${top}px`;
      dropdown.style.left = `${left}px`;
    }
  }, [calendarOpen]);
  const getPopupContainer = () => {
    const popupContainer = document.createElement("div");
    popupContainer.id = "mainCalendarDropdown";
    document.body.appendChild(popupContainer);
    return popupContainer;
  };

  //COMPARE DATE RANGE PICKER
  const sbuttonRef = useRef(null);
  const [scalendarOpen, setSCalendarOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const handleSCalendarOpenChange = (open) => {
    if (!open) {
      setSCalendarOpen(open);
    }
    setOpen();
  };

  const handleSToggleCalendar = () => {
    setSCalendarOpen(!scalendarOpen);
    setOpen(true);
  };
  useEffect(() => {
    if (scalendarOpen) {
      const sbuttonPosition = sbuttonRef.current.getBoundingClientRect();
      const cdropdown = document.getElementById("compCalendarDropdown");

      const stop = sbuttonPosition.top + sbuttonPosition.height;
      const sleft = sbuttonPosition.left;

      cdropdown.style.position = "absolute";
      cdropdown.style.top = `${stop}px`;
      cdropdown.style.left = `${sleft}px`;
    }
  }, [scalendarOpen]);
  const handleAdditionalRangesSwitchChange = (checked) => {
    setShowAdditionalRanges(checked);
    if (checked == false) {
      selectedAdditionalVal.current = "None";
      setSDateRange("");
      //todo
    }
  };

  const getSPopupContainer = () => {
    const popupSContainer = document.createElement("div");
    popupSContainer.id = "compCalendarDropdown";
    document.body.appendChild(popupSContainer);
    return popupSContainer;
  };

  const setAdditionalDateRange = (range, index) => {
    selectRef.current.blur();
    setActiveIndex(index);
    setSDateRange(range.value);
    selectedAdditionalVal.current = range.label;
    additionalDateValue.current = range.value;
    setRangeVal(range.label);
    setDateVal("");
  };

  const handleAdditionalRange = (value) => {
    additionalDateValue.current = value;
    selectedAdditionalVal.current = "Custom";
  };

  const handleDateChange = (value) => {
    dateValue.current = value;
    selectedVal.current = "Custom";
  };
  const [form] = Form.useForm();
  props.form.setFieldsValue({
    ["dateRange"]: dateRange,
    ["selectedVal"]: selectedVal,
    ["selectedAdditionalVal"]: selectedAdditionalVal,
    ["dateSRange"]: dateSRange,
    ["dateFormat"]: dateFormat,
  });

  return (
    <div className="Report-V2-filter-box Report-V2-filter-box-date ">
      <div className="Report-V2-filter-option-title">
        <span>{props?.block?.label}</span>
      </div>
      <div className="Report-V2-filter-box-date-wrapper">
        <div className="main-daterange">
          <Form.Item
            name="dateRange"
            value={{
              dateRange,
              selectedVal,
              selectedAdditionalVal,
              dateValue,
              dateFormat,
            }}
          ></Form.Item>
          <Form.Item name="selectedVal"></Form.Item>
          <Form.Item name="dateFormat"></Form.Item>
          <DatePicker.RangePicker
            value={dateRange}
            onCalendarChange={(value) => setDateRange(value)}
            onChange={handleDateChange}
            open={calendarOpen}
            onOpenChange={handleCalendarOpenChange}
            format={customFormat}
            allowClear={false}
            inputReadOnly={true}
            suffixIcon={null}
            getPopupContainer={getPopupContainer}
            picker={pickerType}
            dropdownClassName="inputrange-calendar"
          />
        </div>
        <Form.Item name="selectedAdditionalVal"></Form.Item>
        {showAdditionalRanges != false &&
        dateVal == "" &&
        showAdditionalOptions != "false" ? (
          <div className="compare-daterange">
            <Form.Item name="dateSRange"></Form.Item>
            <DatePicker.RangePicker
              className="compare-daterange"
              value={dateSRange}
              onCalendarChange={(value) => setSDateRange(value)}
              onChange={handleAdditionalRange}
              open={scalendarOpen}
              onOpenChange={handleSCalendarOpenChange}
              format={customFormat}
              allowClear={false}
              inputReadOnly={true}
              suffixIcon={null}
              getPopupContainer={getSPopupContainer}
              picker={pickerType}
              dropdownClassName="inputrange-calendar"
            />
          </div>
        ) : showAdditionalOptions != "false" ? (
          <span style={{ fontSize: "10px", color: "gray" }}>
            Compared to None
          </span>
        ) : (
          ""
        )}

        <Select
          dropdownClassName="input-dropdown-menu"
          ref={selectRef}
          open={open}
          defaultValue={defaultRangeOption}
          onChange={handlePresetRangeSelect}
          dropdownRender={(menu) => (
            <div className="daterange-picker-addition">
              {menu}
              <button
                ref={buttonRef}
                onClick={handleToggleCalendar}
                className="daterange-custom-button"
              >
                {month_range ? month_range : "Custom"}
              </button>
              {hideToggleSwitch == "true" ? (
                hideText == "true" ? (
                  ""
                ) : (
                  <div className="daterange-compare-toggle">
                    <span>Compared to:</span>
                  </div>
                )
              ) : showAdditionalOptions != "false" ? (
                <div className="daterange-compare-toggle">
                  <Switch
                    size="small"
                    checked={showAdditionalRanges}
                    onChange={handleAdditionalRangesSwitchChange}
                  />
                  <span>Compare</span>
                </div>
              ) : (
                ""
              )}
              {showAdditionalRanges && showAdditionalOptions != "false" && (
                <>
                  <div className="additonal-preset-range-list">
                    {additionalPresetRangesList.map((range, index) => (
                      <div
                        className={`additonal-preset-range ${
                          defaultCRangeOption === range.label ||
                          activeIndex === index
                            ? "apr-active"
                            : ""
                        }`}
                        key={range.label}
                        onClick={() => setAdditionalDateRange(range, index)}
                      >
                        {range.label}
                      </div>
                    ))}
                  </div>
                  <button
                    ref={sbuttonRef}
                    onClick={handleSToggleCalendar}
                    className="daterange-custom-button"
                  >
                    {compare_with ? compare_with : "Custom"}
                  </button>
                </>
              )}
            </div>
          )}
        >
          {preset_range_list == undefined &&
            preset_range_list != "false" &&
            Object.keys(presetRangesList).map((range) => (
              <Option key={range} value={range}>
                {capitalizeFirstLetter(range.replaceAll("_", " "))}
              </Option>
            ))}
        </Select>
      </div>
    </div>
  );
};

export default InputRangePicker;
