import React, { useEffect, useState } from "react";
import { getChannelPerformanceData } from "../../Redux/Actions/Thunk/channelPerformanceThunk";
import { formDataAppend } from "../../Utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Tooltip, Spin } from "antd";
import { format_data } from "../../Utils/helpers";
import ArrowInsights from "../Tables/arrow-insights";
import { FaInfoCircle } from "react-icons/fa";
import Highcharts from "highcharts";
import moment from "moment";

function ChannelPerformance(props) {
  const {
    channel_performance: {
      channel_performance: {
        [props?.block?.block_id]: { data: channelData = [] } = {},
      },
      isLoading,
    } = {},
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getChannelPerformanceData(formData, props));
  }, []);

  var all_input_data = props?.all_input_data;
  var f_date = all_input_data["f_date"];
  var t_date = all_input_data["t_date"];
  var month = all_input_data["month"];
  if (all_input_data["date_range_compareto"] == "None") {
    var date_range_compareto = "None";
    var append_date = "&date_range_compareto=None";
  } else {
    var c_f_date = all_input_data["c_f_date"];
    var c_t_date = all_input_data["c_t_date"];
    var append_date =
      "&c_f_date=" + c_f_date + "&c_t_date=" + c_t_date + "&month=" + month+"&date_range_option="+all_input_data["date_range_option"];
  }

  if (all_input_data["component_type"] == "monthrangepicker") {
    var f_date = moment(all_input_data["f_date"]).format("YYYYMMDD");
    var t_date = moment(all_input_data["t_date"])
      .endOf("month")
      .format("YYYYMMDD");
    if (all_input_data["month_range_compareto"] == "None") {
      var month_range_compareto = "None";
      var append_date = "&date_range_compareto=None&date_range_option="+all_input_data["date_range_option"];
    } else {
      var c_f_date = moment(all_input_data["c_f_date"]).format("YYYYMMDD");
      var c_t_date = moment(all_input_data["c_t_date"])
        .endOf("month")
        .format("YYYYMMDD");
      var append_date =
        "&c_f_date=" + c_f_date + "&c_t_date=" + c_t_date + "&month=" + month+"&date_range_option="+all_input_data["date_range_option"];
    }
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <Row>
          {channelData?.map((table) => {
            return (
              <Col span={24}>
                <div className="channel-performance-table">
                  <table aria-label="">
                    <thead>
                      <tr>
                        <th
                          style={{
                            textAlign: "left",
                            width: "13%",
                          }}
                        >
                          {table?.channel_name == "Email" ? (
                            table?.channel_name
                          ) : (
                            <a
                              href={
                                "/reports/v2/view/" +
                                table?.report_key +
                                "/" +
                                props?.account_id +
                                "?f_date=" +
                                f_date +
                                "&t_date=" +
                                t_date +
                                append_date
                              }
                              target="_blank"
                            >
                              {table?.channel_name}{" "}
                            </a>
                          )}
                          {table?.channel_name == "Amazon Organic" ? (
                            <Tooltip
                              title="Values may be negative if Ad performance exceeds Total Performance"
                              overlayClassName="heading-tooltip"
                            >
                              <span>
                                <FaInfoCircle />
                              </span>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </th>
                        {table?.channel_name == "Amazon Organic" ? (
                          <>
                            {" "}
                            <th
                              style={{
                                textAlign: "left",
                                width: "7%",
                              }}
                            >
                              Buy Box Views
                              <Tooltip
                                title="Organic Buy Box Views = Buy Box Page Views - Ad Clicks (for Sponsored Products & Sponsored Display only)"
                                overlayClassName="heading-tooltip"
                              >
                                <span>
                                  <FaInfoCircle />
                                </span>
                              </Tooltip>
                            </th>
                          </>
                        ) : (
                          <>
                            {" "}
                            <th
                              style={{
                                textAlign: "center",
                                width: "7%",
                              }}
                            >
                              Sessions
                            </th>
                          </>
                        )}

                        <th
                          style={{
                            textAlign: "center",
                            width: "6%",
                          }}
                        >
                          CHANGE
                        </th>
                        {table?.channel_name == "Organic Search" ||
                        table?.channel_name == "Amazon Organic" ||
                        table?.channel_name == "Email" ? (
                          <>
                            {" "}
                            <th
                              style={{
                                textAlign: "center",
                                width: "7%",
                              }}
                            ></th>
                            <th
                              style={{
                                textAlign: "center",
                                width: "7%",
                              }}
                            ></th>
                          </>
                        ) : (
                          <>
                            <th
                              style={{
                                textAlign: "center",
                                width: "7%",
                              }}
                            >
                              AD SPEND
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                width: "7%",
                              }}
                            >
                              CHANGE
                            </th>
                          </>
                        )}

                        <th
                          style={{
                            textAlign: "center",
                            width: "7%",
                          }}
                        >
                          REVENUE
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            width: "7%",
                          }}
                        >
                          CHANGE
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            width: "7%",
                          }}
                        >
                          CONVERSIONS
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            width: "7%",
                          }}
                        >
                          CHANGE
                        </th>
                        {table?.channel_name == "Organic Search" ||
                        table?.channel_name == "Amazon Organic" ||
                        table?.channel_name == "Email" ? (
                          <>
                            {" "}
                            <th
                              style={{
                                textAlign: "center",
                                width: "7%",
                              }}
                            ></th>
                            <th
                              style={{
                                textAlign: "center",
                                width: "7%",
                              }}
                            ></th>
                          </>
                        ) : (
                          <>
                            <th
                              style={{
                                textAlign: "center",
                                width: "7%",
                              }}
                            >
                              ROAS
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                width: "7%",
                              }}
                            >
                              CHANGE
                            </th>
                          </>
                        )}
                        <th
                          style={{
                            textAlign: "center",
                            width: "11%",
                          }}
                        >
                          CONVERSION RATE
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            width: "7%",
                          }}
                        >
                          CHANGE
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {table?.map((item, index) => ( */}
                      <tr>
                        <td>Month to Date</td>
                        <td>{format_data(table.sessions, "number")}</td>
                        <td>
                          <ArrowInsights
                            value={
                              table?.session_change || table?.change_sessions
                            }
                            metric="sessions"
                          />
                          {Highcharts.numberFormat(
                            table.session_change || table?.change_sessions,
                            0,
                            ".",
                            ","
                          )}
                          %
                        </td>
                        {table?.channel_name == "Organic Search" ||
                        table?.channel_name == "Amazon Organic" ||
                        table?.channel_name == "Email" ? (
                          <>
                            <td></td>
                            <td></td>
                          </>
                        ) : (
                          <>
                            <td>{format_data(table.cost, "amount", 0)}</td>
                            <td>
                              <ArrowInsights
                                value={table?.cost_change}
                                metric="ad_spend"
                              />
                              {Highcharts.numberFormat(
                                table.cost_change,
                                0,
                                ".",
                                ","
                              )}
                              %
                            </td>
                          </>
                        )}

                        <td>{format_data(table.revenue, "amount", 0)}</td>
                        <td>
                          <ArrowInsights
                            value={table?.revenue_change}
                            metric="revenue"
                          />
                          {table.revenue_change}%
                        </td>
                        <td>
                          {format_data(
                            table?.conversions || table?.total_conversions,
                            "number"
                          )}
                        </td>
                        <td>
                          <ArrowInsights
                            value={
                              table?.conversion_change ||
                              table?.change_total_conversions
                            }
                            metric="conversions"
                          />
                          {Highcharts.numberFormat(
                            table.conversion_change ||
                              table?.change_total_conversions,
                            0,
                            ".",
                            ","
                          )}
                          %
                        </td>
                        {table?.channel_name == "Organic Search" ||
                        table?.channel_name == "Amazon Organic" ||
                        table?.channel_name == "Email" ? (
                          <>
                            <td></td>
                            <td></td>
                          </>
                        ) : (
                          <>
                            <td>{format_data(table.roas, "percentage")}</td>
                            <td>
                              <ArrowInsights
                                value={table?.roas_change}
                                metric="roas"
                              />
                              {Highcharts.numberFormat(
                                table.roas_change,
                                0,
                                ".",
                                ","
                              )}
                              %
                            </td>
                          </>
                        )}

                        <td>
                          {format_data(
                            table.conversion_rate,
                            "percentage",
                            2,
                            "conversion_rate"
                          )}
                        </td>
                        <td>
                          <ArrowInsights
                            value={table?.conversion_rate_change}
                            metric="conversion_rate"
                          />
                          {Highcharts.numberFormat(
                            table.conversion_rate_change,
                            0,
                            ".",
                            ","
                          )}
                          %
                        </td>
                      </tr>
                      {/* ))} */}
                    </tbody>
                  </table>
                </div>
              </Col>
            );
          })}
        </Row>
      </Spin>
    </>
  );
}

export default ChannelPerformance;
