import { Input, Select, Form } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { formDataAppend, titleCase } from "../../Utils/helpers";
import { Spin, Switch } from "antd";
import Config from "../../Config";
import moment from "moment";
import ReactHtmlParser from "html-react-parser";
import { useSelector, useDispatch } from "react-redux";
import { setDefaults } from "../../Redux/Actions/inputControlAction";
import { getUserId, isIMSUser, isSpecialist } from "../../Utils/user";
import { Token } from "../../Utils/helpers/token";

const {
  API: { baseURL, authKey },
} = Config;

const { Option } = Select;
const onChange = (value) => {};
const onSearch = (value) => {};
const SelectInputComponent = (props) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params_data = Object.fromEntries(urlSearchParams.entries());
  const [secondarySpecialist, setSecondarySpecialist] = useState(true);
  const [secondaryStatus, setSecondaryStatus] = useState(1);
  const [secondarySpecialist2, setSecondarySpecialist2] = useState(false);
  const [secondaryStatus2, setSecondaryStatus2] = useState(0);
  const [selectedValues, setSelectedValues] = useState(
    props.form.getFieldValue(props.block.id) || []
  );

  const {
    inputcontrols: { defaults: defaults = [] },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    getInputData();
  }, []);

  const getInputData = async () => {
    try {
      var formdata = new FormData();
      formdata.append(
        "authentication",
        Token.encryptAuthParams(window.localStorage.getItem("token"))
      );
      if (
        (props?.client_id &&
          props?.client_id !== "" &&
          props?.client_id.length != 0) ||
        (props?.block?.hasOwnProperty("master_dependency") &&
          typeof props?.block?.master_dependency == "object" &&
          (props?.block?.master_dependency[0]?.id).includes("Client"))
      ) {
        formdata.append("Client", props?.client_id);
      }

      if (
        props?.block?.hasOwnProperty("master_dependency") &&
        typeof props?.block?.master_dependency == "object" &&
        props?.block?.master_dependency[0].hasOwnProperty("report_key") &&
        (props?.block?.master_dependency[0]?.report_key).includes(
          "campaigns_report_key"
        )
      ) {
        formdata.append("campaigns_report_key", props?.report_key);
      }

      if (
        (props?.client_id &&
          props?.client_id !== "" &&
          props?.client_id.length != 0) ||
        (props?.block?.hasOwnProperty("master_dependency") &&
          typeof props?.block?.master_dependency == "object" &&
          (props?.block?.master_dependency[0]?.id).includes("year"))
      ) {
        formdata.append("year", "2023");
      }

      if (
        props?.block?.hasOwnProperty("master_dependency") &&
        typeof props?.block?.master_dependency == "object" &&
        (props?.block?.master_dependency[0]?.id).includes("feed_service_id")
      ) {
        formdata.append("feed_service_id", 7);
      }

      if (
        props?.block?.hasOwnProperty("master_dependency") &&
        typeof props?.block?.master_dependency == "object" &&
        (props?.block?.master_dependency[0]?.id).includes("source")
      ) {
        formdata.append("source", "pla_ppc_common");
      }

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      setLoading(true);
      const response = await fetch(
        baseURL + props?.block?.option_url,
        requestOptions
      );
      const result = await response.json();
      setDataSource(result?.data);
    } catch (err) {
      // Handle any errors
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let defaultValue = props?.block?.default;

    const userId = getUserId();

    if (defaultValue != "-1") {
      if (
        isIMSUser() &&
        defaultValue != "%%current_user_id_specialist%%" &&
        defaultValue == "%%current_user_id_ims%%"
      ) {
        if (dataSource.length > 0) {
          const ims_ids = dataSource?.map((data) => data.id);
          if (ims_ids.includes(userId)) {
            defaultValue = userId;
            dispatch(setDefaults({ [props?.block?.id]: defaultValue }));
          } else {
            defaultValue = props?.block?.default;
            dispatch(setDefaults({ [props?.block?.id]: defaultValue }));
          }
        }
      }
      if (
        isSpecialist() &&
        defaultValue != "%%current_user_id_ims%%" &&
        defaultValue == "%%current_user_id_specialist%%"
      ) {
        if (dataSource.length > 0) {
          const specialist_ids = dataSource?.map((data) => data.id);
          if (specialist_ids.includes(userId)) {
            defaultValue = userId;
            dispatch(setDefaults({ [props?.block?.id]: defaultValue }));
          } else {
            defaultValue = props?.block?.default;
            dispatch(setDefaults({ [props?.block?.id]: defaultValue }));
          }
        }
      }
    }
  }, [dataSource?.length > 0 && props?.block?.default != null]);

  useEffect(() => {
    let defaultValue = props?.block?.default;

    if (urlSearchParams.get(props?.block?.id) != null) {
      defaultValue = params_data[props?.block?.id];

      dispatch(setDefaults({ [props?.block?.id]: defaultValue }));
    } else {
      dispatch(setDefaults({ [props?.block?.id]: defaultValue }));
    }
  }, [window?.location?.search]);

  useEffect(() => {
    if (defaults.hasOwnProperty(props?.block?.id)) {
      var myDefaultValue = defaults[props?.block?.id];
      if (
        props?.block?.id == "customer" &&
        params_data.hasOwnProperty("customerName")
      ) {
        myDefaultValue = params_data["customerName"];
      }
      props.form.setFieldsValue({
        [props?.block?.id]: myDefaultValue,
      });
    }
  }, [defaults]);

  //for deafult select if default not available in json
  useEffect(() => {
    if (props?.block?.default == "" && dataSource.length > 0) {
      const paramValue = String(dataSource[0].value);
      const keyData = String(dataSource[0].id);
      const paramKey = props?.block?.param_name;

      props.form.setFieldsValue({
        [paramKey]: keyData,
      });
    }
  }, [dataSource, props?.block?.default == ""]);

  const handleSwitchChange = (checked) => {
    let status = checked == true ? 1 : 0;
    setSecondarySpecialist(status);
    setSecondaryStatus(status);
    props.form.setFieldsValue({ [props?.block?.switchId]: status });
  };

  const handleSwitchChange2 = (checked) => {
    let status = checked == true ? 1 : 0;
    setSecondarySpecialist2(status);
    setSecondaryStatus2(status);
    props.form.setFieldsValue({ [props?.block?.switchId2]: status });
  };

  const allFields = ["-1", "All"];
  const handleSelect = (items) => {
    if (items.length === 0 && props?.block?.default === "-1") {
      items = ["-1"];
      setSelectedValues("-1");
    }
    if (items.length === 0 && props?.block?.default === "All") {
      items = ["All"];
      setSelectedValues("All");
    }
    const latestValue = items[items.length - 1];

    if (allFields.includes(latestValue)) {
      items = [latestValue];
    } else {
      const findIndexMinusOne = items.indexOf("-1");
      if (findIndexMinusOne > -1) {
        items.splice(findIndexMinusOne, 1);
      }

      const findIndexAll = items.indexOf("All");
      if (findIndexAll > -1) {
        items.splice(findIndexAll, 1);
      }
    }

    if (Array.isArray(items)) {
      setSelectedValues(items);
      props.form.setFieldsValue({ [props.block.id]: items });
    } else {
      setSelectedValues([]);
      props.form.setFieldsValue({ [props.block.id]: [] });
    }
  };
  return (
    <>
      <div
        className={
          "Report-V2-filter-box select_filter" + " " + props?.block.param_name
        }
      >
        <Spin spinning={loading}>
          <div className="Report-V2-filter-option-title">
            <span>{props?.block?.label}</span>
          </div>

          <div className="toggle-handler-wrapper">
            <div className="toggle-handler-upper">
              {props?.block?.switchId != undefined ? (
                <>
                  <Form.Item
                    name={props?.block?.switchId}
                    initialValues="1"
                    style={{ display: "none" }}
                  >
                    <Input placeholder="" value={secondaryStatus} />
                  </Form.Item>

                  <Form.Item name={"switch_" + props?.block?.switchId}>
                    <span style={{ fontSize: "12px" }}>
                      <Switch
                        size="small"
                        checked={secondarySpecialist}
                        onChange={handleSwitchChange}
                      />{" "}
                      {props?.block?.switchLabel}
                    </span>
                  </Form.Item>
                </>
              ) : (
                ""
              )}
            </div>

            <div className="toggle-handler-upper">
              {props?.block?.switchId2 != undefined ? (
                <>
                  <Form.Item
                    name={props?.block?.switchId2}
                    initialValues="0"
                    style={{ display: "none" }}
                  >
                    <Input placeholder="" value={secondaryStatus2} />
                  </Form.Item>

                  <Form.Item name={"switch_" + props?.block?.switchId2}>
                    <span style={{ fontSize: "12px" }}>
                      <Switch
                        size="small"
                        checked={secondarySpecialist2}
                        onChange={handleSwitchChange2}
                      />{" "}
                      {props?.block?.switchLabel2}
                    </span>
                  </Form.Item>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          <Form.Item name={props?.block.id}>
            <Select
              dropdownClassName="input-dropdown-menu"
              key={props?.block?.label}
              name={props?.block?.label}
              optionLabelProp="key"
              dropdownStyle={{
                maxWidth: "500px",
              }}
              mode={props?.block?.mode_type}
              showSearch
              maxTagCount={1}
              placeholder="All"
              value={selectedValues}
              onChange={
                props?.block?.mode_type == "multiple" ? handleSelect : onChange
              }
              optionFilterProp="children"
              // onChange={onChange}

              // onSearch={onSearchform}

              dropdownMatchSelectWidth={false}
              //listHeight={128}

              // open={true}

              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataSource?.map((data) => (
                <Option
                  value={
                    data?.id == "-1" || data?.id == "All"
                      ? props?.block?.default
                      : data?.id
                  }
                  id={data.id}
                  name={data.id}
                  key={data.value}
                >
                  {typeof data.value == "object"
                    ? data.value
                    : ReactHtmlParser(String(data.value))}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Spin>
      </div>
    </>
  );
};

export default SelectInputComponent;
