import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Donut from "../Highcharts/Donut";
import Column from "../Highcharts/Column";
import { formDataAppend } from "../../Utils/helpers";
import { getDevicePerformance } from "../../Redux/Actions/Thunk/TrendThunk";
import { Spin } from "antd";
import { format_data } from "../../Utils/helpers";
import { FiSquare } from "react-icons/fi";
import HtmlParser from "html-react-parser";
import { Tooltip } from "antd";
import { FaInfoCircle } from "react-icons/fa";

const PerformanceGraph = (props) => {
  const tableRef = useRef(null);
  const chartRef = useRef(null);
  const {
    trend: {
      devicePerformance: {
        [props?.block?.block_id]: {
          json_data: jsonData = [],
          data: tbl_data = [],
          table_columns: TableColumns = [],
          labels_new: labelNew = [],
          table_total: tableTotal = [],
          insights_data: insightData = [],
        } = {},
      },
      isLoading,
    } = {},
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getDevicePerformance(formData, props));
  }, []);

  //get table column
  const column = TableColumns ? TableColumns : [];
  //get table heading data
  const ThData = () => {
    return column?.map((data, i) => {
      return (
        <th key={i}>
          {data}{" "}
          {labelNew?.map((v, idx) =>
            v?.tooltip_data != undefined &&
              v.tooltip_data != "" &&
              data === v?.value ? (
              <Tooltip color="black" title={v?.tooltip_data}>
                <span style={{ color: "#8dc441" }}>
                  <FaInfoCircle />
                </span>
              </Tooltip>
            ) : (
              ""
            )
          )}
        </th>
      );
    });
  };
  // get table row data
  const tdData = () => {
    return tbl_data.map((data, index) => {
      return (
        <tr key={index}>
          {labelNew?.map((v, idx) => {
            return (
              <>
                {idx === 0 ? (
                  <td
                    style={{
                      textTransform: "capitalize",
                    }}
                    className="performance-report-color-icon"
                    key={v.key}
                  >
                    <span>
                      <FiSquare
                        color={jsonData[0]?.data[index]?.color}
                        fill={jsonData[0]?.data[index]?.color}
                      />
                    </span>

                    {data[v.key] ? data[v.key] : data['device']}
                  </td>
                ) : (
                  <td key={v.key}>
                    {v?.format == "amount"
                      ? format_data(data[v.key], v?.format, 2, v?.key)
                      : v?.format == "percentage" && v?.value == "Change"
                        ? format_data(data[v.key], v?.format, 100, v?.key, true)
                        : v?.format == "percentage"
                          ? format_data(data[v.key], v?.format, 0, v?.key)
                          : v?.format == "number"
                            ? format_data(data[v.key], v?.format)
                            : data[v.key]}
                  </td>
                )}
              </>
            );
          })}
        </tr>
      );
    });
  };
  useEffect(() => {
    if (tableRef.current && chartRef.current) {
      const firstTd = tableRef.current.querySelector('td:first-child');
      if (firstTd) {
        const tdWidth = firstTd.offsetWidth;
        chartRef.current.style.paddingLeft = `${tdWidth}px`;
      }
    }
  });
  return (
    <>
      <Spin spinning={isLoading}>
        {jsonData && jsonData.length > 0 ? (
          <>
            <div className="performance-graph-chart-wrapper" ref={chartRef}>
              <Donut data={jsonData} props={props} />
              <Column data={jsonData} />
            </div>
            <div className="performance-datatable">
              <table id="performance-table" ref={tableRef}>
                <thead>
                  <tr>{ThData()}</tr>
                </thead>
                <tbody>{tdData()}</tbody>
                <tfoot>
                  <tr>
                    <td>Total</td>
                    {labelNew?.map((v, idx) => {
                      if (idx !== 0) {
                        const totalData = tableTotal.find(
                          (total_data) => total_data.key === v.key
                        );
                        if (totalData) {
                          return (
                            <td key={v.key}>
                              {totalData.format === "amount"
                                ? format_data(
                                  totalData.data,
                                  totalData.format,
                                  2,
                                  totalData.key, null
                                )
                                : totalData.format === "number"
                                  ? format_data(totalData.data, totalData.format, 0, totalData.key)
                                  : totalData.format === "percentage" && v?.value == "Change"
                                    ? format_data(totalData.data, totalData.format, 100, totalData.key, true)
                                    : totalData.format === "percentage"
                                      ? format_data(totalData.data, totalData.format, 0, totalData.key)
                                      : totalData.data}
                            </td>
                          );
                        } else {
                          return <td key={v.key}></td>;
                        }
                      }
                      return null;
                    })}
                  </tr>
                </tfoot>
              </table>
            </div>
          </>
        ) : (
          "No Data Available"
        )}
        {insightData && insightData.length > 0 ? (
          <div className="insights">
            <h4>Insights</h4>
            <ul>
              {insightData.map((insight) => (
                <li>{HtmlParser(insight)}</li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
      </Spin>
    </>
  );
};

export default PerformanceGraph;
