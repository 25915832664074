import { Popconfirm, Tooltip, Button } from "antd";
import React, { useState } from "react";
import { getDataTable } from "../../Redux/Actions/Thunk/dataTableThunk";
import AccountDataStatus from "./../../Pages/Tables/CustomComponents/AccountDataStatus";
import AccountManagerDashboard from "./../../Pages/Tables/CustomComponents/AccountManagerDashboard";
import EmailClientImpact from "../../Pages/Tables/CustomComponents/EmailClientImpact";
import AmazonMarketingDailySpend from "../../Pages/Tables/CustomComponents/AmazonMarketingDailySpend";
import AmazonSponsoredAdsDailySpend from "../../Pages/Tables/CustomComponents/AmazonSponsoredAdsDailySpend";
import ConversionClientImpact from "../../Pages/Tables/CustomComponents/ConversionClientImpact";
import CustomerLifetimeValue from "../../Pages/Tables/CustomComponents/CustomerLifetimeValue";
import HolisticClientImpact from "../../Pages/Tables/CustomComponents/HolisticClientImpact";
import OrganicSearchClientImpact from "../../Pages/Tables/CustomComponents/OrganicSearchClientImpact";
import PaidMarketingClientImpact from "../../Pages/Tables/CustomComponents/PaidMarketingClientImpact";
import PaidSearchDailySpend from "../../Pages/Tables/CustomComponents/PaidSearchDailySpend";
import ProductAdsDailySpend from "../../Pages/Tables/CustomComponents/ProductAdsDailySpend";
import ProductApprovalReport from "../../Pages/Tables/CustomComponents/ProductApprovalReport";
import SocialDailySpend from "../../Pages/Tables/CustomComponents/SocialDailySpend";
import UserLoginLogs from "../../Pages/Tables/CustomComponents/UserLoginLogs";
import SocialPerformance from "../../Pages/Tables/CustomComponents/SocialPerformance";
import PaidSocialAdPerformance from "../../Pages/Tables/CustomComponents/PaidSocialAdPerformance";
import TikTokAdsPerformance from "../../Pages/Tables/CustomComponents/TikTokAdsPerformance";
import AdwordsSearchTermInsights from "../../Pages/Tables/CustomComponents/AdwordsSearchTermInsights";
import { FormattedNumberCell } from "./formatting";
import { FaInfoCircle } from "react-icons/fa";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import InsightWorkflowNotification from "../../Pages/Tables/CustomComponents/InsightWorkflowNotification";
import LandingPageInsights from "../../Pages/Tables/CustomComponents/LandingPageInsights";
import { formDataAppend } from "../helpers";
import { ACTIONS_TYPES } from "../../Redux/Reducers/dataTableReducer";
import Config from "../../Config";
import ProductAdsPerformance from "../../Pages/Tables/CustomComponents/ProductAdsPerformance";
import AdwordSearchTermPerformance from "../../Pages/Tables/CustomComponents/AdwordSearchTermPerfromance";
import BingSearchTermInsights from "../../Pages/Tables/CustomComponents/BingSearchTermInsights";
import BingProductInsights from "../../Pages/Tables/CustomComponents/BingProductInsights";
import AdwordsProductInsights from "../../Pages/Tables/CustomComponents/AdwordsProductInsights";
import AmazonSearchTermPerformance from "../../Pages/Tables/CustomComponents/AmazonSearchTermPerformance";
import SearchTermsWithNegativeKeywords from "../../Pages/Tables/CustomComponents/SearchTermsWithNegativeKeywords";
import AnnotationsReport from "../../Pages/Tables/CustomComponents/AnnotationsReport";
import AmazonRatings from "../../Pages/Tables/CustomComponents/AmazonRatings";
import AmazonSponsoredAdsFulfillmentInsights from "../../Pages/Tables/CustomComponents/AmazonSponsoredAdsFulfillmentInsights";
import SeoPageTypeDrillDown from "../../Pages/Tables/CustomComponents/SeoPageTypeDrillDown";
import AmazonSearchTermInsights from "../../Pages/Tables/CustomComponents/AmazonSearchTermInsights";
import PerformanceMaxInsightsReport from "../../Pages/Tables/CustomComponents/PerformanceMaxInsightsReport";
import GlowRecipeFBAdsPerformance from "../../Pages/Tables/CustomComponents/GlowRecipeFBAdsPerformance";
import UsageAllUsers from "../../Pages/Tables/CustomComponents/UsageAllUsers";
import MonocleAccountSetupStatus from "../../Pages/Tables/CustomComponents/MonocleAccountSetupStatus";
import OrganicSearchLeadGenPerformance from "../../Pages/Tables/CustomComponents/OrganicSearchLeadGenPerformance";
import WebsiteHolisticLeadgenPerformance from "../../Pages/Tables/CustomComponents/WebsiteHolisticLeadgenPerformance";
import KlaviyoEmailPerformance from "../../Pages/Tables/CustomComponents/KlaviyoEmailPerformance";
import KlaviyoSMSPerformance from "../../Pages/Tables/CustomComponents/KlaviyoSMSPerformance";
import { notification } from "antd";
import AmazonProductPerformance from "../../Pages/Tables/CustomComponents/AmazonProductPerformance";
import DataAnamolyDuplicate from "../../Pages/Tables/CustomComponents/DataAnamolyDuplicate";

const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  });
};
const {
  API: { baseURL },
} = Config;

export const componentMatchReport = {
  amazon_ratings: AmazonRatings,
  user_login_logs: UserLoginLogs,
  usage_all_users: UsageAllUsers,
  social_daily_spend: SocialDailySpend,
  social_performance: SocialPerformance,
  annotations_report: AnnotationsReport,
  account_data_status: AccountDataStatus,
  email_client_impact: EmailClientImpact,
  landing_page_insights: LandingPageInsights,
  bing_product_insights: BingProductInsights,
  holistic_client_impact: HolisticClientImpact,
  tiktok_ads_performance: TikTokAdsPerformance,
  paid_search_daily_spend: PaidSearchDailySpend,
  product_ads_daily_spend: ProductAdsDailySpend,
  customer_lifetime_value: CustomerLifetimeValue,
  product_approval_report: ProductApprovalReport,
  product_ads_performance: ProductAdsPerformance,
  conversion_client_impact: ConversionClientImpact,
  adwords_product_insights: AdwordsProductInsights,
  bing_search_term_insights: BingSearchTermInsights,
  account_manager_dashboard: AccountManagerDashboard,
  paid_social_ad_performance: PaidSocialAdPerformance,
  amazon_product_performance: AmazonProductPerformance,
  amazon_search_term_insights: AmazonSearchTermInsights,
  amazon_marketing_daily_spend: AmazonMarketingDailySpend,
  organic_search_client_impact: OrganicSearchClientImpact,
  paid_marketing_client_impact: PaidMarketingClientImpact,
  adwords_search_term_insights: AdwordsSearchTermInsights,
  monocle_account_setup_status: MonocleAccountSetupStatus,
  insight_workflow_notification: InsightWorkflowNotification,
  seo_page_type_performance_drill_down: SeoPageTypeDrillDown,
  glow_recipe_fb_ads_performance: GlowRecipeFBAdsPerformance,
  amazon_search_term_performance: AmazonSearchTermPerformance,
  adwords_search_term_performance: AdwordSearchTermPerformance,
  performance_max_insights_report: PerformanceMaxInsightsReport,
  amazon_sponsored_ads_daily_spend: AmazonSponsoredAdsDailySpend,
  search_terms_with_negative_keywords: SearchTermsWithNegativeKeywords,
  organic_search_lead_gen_performance: OrganicSearchLeadGenPerformance,
  website_holistic_lead_gen_performance: WebsiteHolisticLeadgenPerformance,
  klaviyo_email_marketing: KlaviyoEmailPerformance,
  klaviyo_sms_marketing: KlaviyoSMSPerformance,
  amazon_sponsored_ads_fulfillment_insights:
    AmazonSponsoredAdsFulfillmentInsights,
  data_anomaly_duplicate: DataAnamolyDuplicate,
};

export const tableCellHelper = (
  report_key,
  text,
  record,
  column,
  setLoading,
  props,
  clv_cohorts_val,
  goalData,
  notifications,
  notification_services,
  abtest_winner,
  abtest_kpi
) => {
  const Component = componentMatchReport[report_key];
  if (!Component) return null;
  return (
    <Component
      value={text}
      key={Math.round()}
      record={record}
      column={column}
      setLoading={setLoading}
      extraParams={props}
      clv_cohorts_val={clv_cohorts_val}
      goalData={goalData}
      notifications={notifications}
      abtest_winner={abtest_winner}
      abtest_kpi={abtest_kpi}
    />
  );
};

export const renderColumns = (
  columns,
  getColumnSearchProps,
  setLoading,
  props,
  clv_cohorts_val
) => {
  const pathArray = window.location?.pathname?.split("/");
  let report_key_name = pathArray[4];

  return columns.map((column) => {
    const columnHeaderTooltip = column.tooltip ? (
      <Tooltip title={column.tooltip}>
        <span style={{ color: "#8dc441" }}>
          <FaInfoCircle />
        </span>
      </Tooltip>
    ) : null;
    if (
      column.children &&
      props?.all_input_data?.include_amazon_data === 0 &&
      props?.block?.report_key === "media_mix_modeling_v2"
    ) {
      return {
        ...column,
        children: column.children
          .map((child) => {
            if (
              (props?.all_input_data?.include_amazon_data === 0 &&
                child.dataIndex === "asc_spend_perc") ||
              child.dataIndex === "asc_lift"
            ) {
              return null;
            }
            return {
              ...child,
              render: (text, record) => {
                return (
                  <>
                    {<FormattedNumberCell value={text} format={child.format} />}
                  </>
                );
              },
            };
          })
          .filter(Boolean),
      };
    } else {
      if (column.children) {
        return {
          ...column,
          children: renderColumns(column.children, getColumnSearchProps),
          title: (
            <>
              {/* {columnHeaderTooltip} */}
              {column.title}
            </>
          ),
          // ...getColumnSearchProps(column.dataIndex),
          render: (text, record) => (
            <span>
              {renderColumns(column.children, getColumnSearchProps).map(
                (nestedColumn) => (
                  <span key={nestedColumn.dataIndex}>
                    {
                      <Tooltip
                        trigger="hover"
                        title={record[nestedColumn.tooltip]}
                      >
                        <span style={{ color: "#8dc441" }}>
                          <FaInfoCircle />
                        </span>
                      </Tooltip>
                    }
                    {nestedColumn.render
                      ? nestedColumn.render(
                          record[nestedColumn.dataIndex],
                          record
                        )
                      : record[nestedColumn.dataIndex]}
                    {" | "}
                  </span>
                )
              )}
            </span>
          ),

          // Add filter props for children columns
        };
      } else {
        const matchReport = componentMatchReport[report_key_name];
        if (matchReport) {
          return {
            ...column,
            title: (
              <>
                {column.title}
                {columnHeaderTooltip}
              </>
            ),
            sorter: true,
            ...getColumnSearchProps(column.dataIndex),
            render: (text, record) => {
              const ValidComponent = tableCellHelper(
                report_key_name,
                text,
                record,
                column,
                setLoading,
                props,
                clv_cohorts_val
              );
              return (
                <>
                  {record.hasOwnProperty("children") &&
                  Array.isArray(record.children) ? (
                    <>{ValidComponent}</>
                  ) : (
                    <>{ValidComponent}</>
                  )}
                </>
              );
            },
          };
        } else {
          return {
            ...column,
            title: (
              <>
                {column.title}
                {columnHeaderTooltip}
              </>
            ),
            render: (text, record) => {
              return (
                <>
                  {
                    <FormattedNumberCell
                      value={text}
                      format={column.format}
                      metric={column.dataIndex}
                    />
                  }
                </>
              );
            },
          };
        }
      }
    }
  });
};

export function extractPlaceholders(url) {
  const pattern = /%([^%]+)%/g;
  const placeholders = [];
  let match;
  while ((match = pattern.exec(url)) !== null) {
    placeholders.push(match[1]);
  }
  return placeholders;
}

export function extractAndReplaceDatePlaceholders(url, fromDate, toDate) {
  const queryIndex = url.indexOf("?");
  if (queryIndex === -1) return url;

  const queryString = url.slice(queryIndex + 1);
  const pattern = /%([^%]+)%/g;

  let updatedQueryString = queryString;
  updatedQueryString = updatedQueryString.replace(/%fromDate%/g, fromDate);
  updatedQueryString = updatedQueryString.replace(/%toDate%/g, toDate);

  return url.slice(0, queryIndex + 1) + updatedQueryString;
}

export function extractAndReplaceFromAndToDatePlaceholders(
  url,
  fromdate,
  todate
) {
  const queryIndex = url.indexOf("?");
  if (queryIndex === -1) return url;

  const queryString = url.slice(queryIndex + 1);
  const pattern = /%([^%]+)%/g;

  let updatedQueryString = queryString;

  updatedQueryString = updatedQueryString.replace(/%from_date%/g, fromdate);
  updatedQueryString = updatedQueryString.replace(/%to_date%/g, todate);

  return url.slice(0, queryIndex + 1) + updatedQueryString;
}

export function extractAndReplaceCompareDatePlaceholders(
  url,
  fromDate,
  toDate,
  fromCompare,
  toCompare
) {
  const queryIndex = url.indexOf("?");
  if (queryIndex === -1) return url;

  const queryString = url.slice(queryIndex + 1);
  const pattern = /%([^%]+)%/g;

  let updatedQueryString = queryString;
  updatedQueryString = updatedQueryString.replace(/%fromDate%/g, fromDate);
  updatedQueryString = updatedQueryString.replace(/%toDate%/g, toDate);
  updatedQueryString = updatedQueryString.replace(
    /%fromCompare%/g,
    fromCompare
  );
  updatedQueryString = updatedQueryString.replace(/%toCompare%/g, toCompare);

  return url.slice(0, queryIndex + 1) + updatedQueryString;
}

export function extractAndReplaceAccountId(url, accountId, record) {
  const queryIndex = url.indexOf("?");
  if (queryIndex === -1) {
    return updateValue(url, queryIndex, url, accountId, record);
  }

  const path = url.slice(0, queryIndex);

  return updateValue(path, queryIndex, url, accountId, record);
}

function updateValue(path, queryIndex, url, accountId, record) {
  const pattern = /%([^%]+)%/g;

  let updatedPath = path;
  let match;
  while ((match = pattern.exec(path)) !== null) {
    if (queryIndex === -1) {
      var placeholder = match[1];
      if (record[placeholder] != undefined) {
        updatedPath = updatedPath.replace(
          new RegExp(`%${placeholder}%`, "g"),
          record[placeholder]
        );
      } else {
        var replacement = accountId || "";
        updatedPath = updatedPath.replace(
          new RegExp(`%${placeholder}%`, "g"),
          replacement
        );
      }

      return updatedPath;
    } else {
      var placeholder = match[1];
      var replacement = accountId || ""; // Use an empty string if the replacement is not provided
      updatedPath = updatedPath.replace(
        new RegExp(`%${placeholder}%`, "g"),
        replacement
      );
      return updatedPath + url.slice(queryIndex);
    }
  }
}

export function extractAndReplaceParams(url, record) {
  const queryIndex = url.indexOf("?");
  if (queryIndex === -1) return url;

  const queryString = url.slice(queryIndex + 1);
  const pattern = /%([^%]+)%/g;

  let updatedQueryString = queryString;
  let match;
  while ((match = pattern.exec(queryString)) !== null) {
    const placeholder = match[1];
    if (
      placeholder !== "fromDate" &&
      placeholder !== "toDate" &&
      placeholder !== "fromCompare" &&
      placeholder !== "toCompare" &&
      placeholder !== "from_date" &&
      placeholder !== "to_date"
    ) {
      const replacement = record[placeholder] || ""; // Use an empty string if the replacement is not provided
      updatedQueryString = updatedQueryString.replace(
        new RegExp(`%${placeholder}%`, "g"),
        replacement
      );
    }
  }

  return url.slice(0, queryIndex + 1) + updatedQueryString;
}

export function extractAndReplaceAbsoluteParams(url, record) {
  let match;
  const pattern = /%([^%]+)%/g;
  match = pattern.exec(url);
  const placeholder = match[1];
  const replacement = record[placeholder] || ""; // Use an empty string if the replacement is not provided
  var updatedQueryString = url.replace(
    new RegExp(`%${placeholder}%`, "g"),
    replacement
  );

  return updatedQueryString;
}

export const exportData = (data_type, props, dispatch, apiPath) => {
  var data = props?.all_input_data;
  Object.assign(data, {
    export_data_type: data_type,
    export_data: "true",
    limit: "10000",
    by_pass_cache: "yes",
  });
  var formdata = formDataAppend(data);
  dispatch({
    type: ACTIONS_TYPES.REQUEST_EXPORT_TABLE,
    payload: props?.block?.block_id,
  });
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  fetch(baseURL + apiPath, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      const export_arr_data = result?.data;
      if (export_arr_data.length == 0) {
        const type = "error";
        const message = "Download Not Available";
        const description = "";
        openNotificationWithIcon(type, message, description);
        return true;
      }
      let extension_data = "";
      if (result.format == "excel") {
        extension_data = "xlsx";
      } else {
        extension_data = result.format;
      }
      const url = `data:${extension_data};base64,${result.data}`;
      const link = document.createElement("a");
      link.href = url;
      link.download = `${props?.block?.block_name}.${extension_data}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => console.log("error", error))
    .finally(() => {
      dispatch({
        type: ACTIONS_TYPES.FETCH_EXPORT_TABLE,
        payload: props?.block?.block_id,
      });
    });
};
