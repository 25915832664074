import { ACTIONS_TYPES } from "../Reducers/LineChartReducer";

export function fetchRevenueRetentionData(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_SESSSION_DATA,
    payload,
  };
}

export function getlineChartData(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_LINE_CHART_DATA,
    payload,
  };
}
