import { message } from "antd";
import * as AccountApi from "../../../Api/AccountApi";
import * as reportLayoutApi from "../../../Api/reportLayoutApi";
import { ACTIONS_TYPES } from "../../Reducers/accountReducer";
import * as accountAction from "../accountAction";
import * as reportLayoutAction from "../reportLayoutAction";

export const getAllAccounts = (props, params = "") => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.ACCOUNT_REQUEST,
      });
      let accounts = {};
      const response = await AccountApi.getAccounts(props, params);
      if (response?.status == 200) {
        accounts = {
          data: response?.data?.data,
          total: response?.data?.total_records,
          notifications: response?.data?.notifications,
          notification_services: response?.data?.notification_services,
        };
        return dispatch(accountAction.getAccount(accounts));
      }
    } catch (error) {
      console.error("Error in fetching accounts data: " + error);
    } finally {
    }
  };
};

export const getFreemiumAccounts = (props, params = "") => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.FREEMIUM_ACCOUNT_REQUEST,
      });
      let accounts = {};

      const response = await AccountApi.getFreemiumAccounts(props, params);

      if (response?.status === 200) {
        accounts = {
          data: response?.data?.data,
          total: response?.data?.total_records,
        };
        return dispatch(accountAction.getFreemiumAccounts(accounts));
      }
    } catch (error) {
      console.error("Error in fetching accounts data: " + error);
    } finally {
    }
  };
};

export const getIMSUsers = () => {
  return async (dispatch) => {
    try {
      let ims_users = {};
      const response = await AccountApi.getIMSUsers();
      if (response?.status == 200) {
        ims_users = response?.data?.data;
      }
      return dispatch(accountAction.getIMSUsers(ims_users));
    } catch (error) {
      console.error("Error in fetching ims users: " + error);
    } finally {
    }
  };
};

export const getSpecialistUsers = () => {
  return async (dispatch) => {
    try {
      let specialist_users = {};
      const response = await AccountApi.getSpecialistUsers();
      if (response?.status == 200) {
        specialist_users = response?.data?.data;
      }
      return dispatch(accountAction.getSpecialistUsers(specialist_users));
    } catch (error) {
      console.error("Error in fetching specialist users: " + error);
    } finally {
    }
  };
};

export const getServices = () => {
  return async (dispatch) => {
    try {
      let services = {};
      var services_data = [];
      const response = await AccountApi.getServices();
      if (response?.status == 200) {
        services_data = response?.data?.data;
        if (services_data.length > 0) {
          services_data.shift();
        }
        services = {
          data: services_data,
        };
      }
      return dispatch(accountAction.getServices(services));
    } catch (error) {
      console.error("Error in fetching services: " + error);
    } finally {
    }
  };
};

export const getFreemiumServices = () => {
  return async (dispatch) => {
    try {
      let services = {};
      const response = await AccountApi.getFreemiumServices();
      if (response?.status == 200) {
        services = {
          data: response?.data?.data,
        };
      }
      return dispatch(accountAction.getFreemiumServices(services));
    } catch (error) {
      console.log("Error in fetching services: " + error);
    }
  };
};

export const getFreemiumClientAccount = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.FREEMIUM_ACCOUNT_REQUEST,
      });

      let accounts = {};

      const response = await AccountApi.getFreemiumAccount(props);

      if (response?.status == 200) {
        accounts = {
          data: response?.data?.data,
        };
      }

      return dispatch(accountAction.getFreemiumAccounts(accounts));
    } catch (error) {
      console.error("Error in fetching accounts data: " + error);
    } finally {
    }
  };
};

export const getContractedServices = (props, params = "") => {
  return async (dispatch) => {
    try {
      let services = {};
      const response = await AccountApi.getContractedServices(props, params);
      if (response?.data?.data) {
        services = {
          data: response?.data?.data,
        };
      }
      dispatch({
        type: ACTIONS_TYPES.FETCH_SERVICES,
        payload: services,
      });
      return dispatch(accountAction.getServices(services));
    } catch (error) {
      console.error("Error in fetching services: " + error);
    } finally {
    }
  };
};

export const getUserAccounts = (props, params = "") => {
  return async (dispatch) => {
    try {
      let accounts = {};
      const response = await AccountApi.getUserAccounts(props, params);
      if (response?.data?.data) {
        accounts = {
          data: response?.data?.data,
        };
      }
      dispatch({
        type: ACTIONS_TYPES.FETCH_USER_ACCOUNTS,
        payload: accounts,
      });
      return dispatch(accountAction.getUserAccounts(accounts));
    } catch (error) {
      console.error("Error in fetching user accounts: " + error);
    } finally {
    }
  };
};

export const getAccountDetail = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.RESET_DATA,
      });
      dispatch({
        type: ACTIONS_TYPES.ACCOUNT_REQUEST,
      });
      let accountDetails = [];
      const response = await AccountApi.getAccountDetail(props);
      if (response?.status == 200) {
        accountDetails = {
          data: response?.data?.data,
          account_name: response?.data?.data?.account?.name,
        };
      }
      return dispatch(accountAction.getAccountDetail(accountDetails));
    } catch (error) {
      console.error("Error in fetching account detail: " + error);
    } finally {
    }
  };
};

export const getFreemiumAccountDetail = (props) => {
  return async (dispatch) => {
    try {
      let accountDetails = [];
      const response = await AccountApi.getFreemiumAccountDetail(props);
      if (response?.status == 200) {
        accountDetails = {
          user: response?.data?.user,
          detail: response?.data?.detail,
          //services: response?.data?.services[0],
          services: response?.data?.services,
          seoReport: response?.data?.seoReport,
          seo: response?.data?.seo,
          adwordsReport: response?.data?.adwordsReport,
          adwords: response?.data?.adwords,
        };
      }
      return dispatch(accountAction.getAccountDetail(accountDetails));
    } catch (error) {
      console.error("Error in fetching account detail: " + error);
    } finally {
    }
  };
};

export const getAccountContacts = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.ACCOUNT_REQUEST,
      });
      let accountContacts = [];
      const response = await AccountApi.getAccountContacts(props);
      if (response?.status == 200) {
        accountContacts = {
          data: response?.data?.contacts,
          account_name: response?.data?.account_name,
        };
      }
      return dispatch(accountAction.getAccountContacts(accountContacts));
    } catch (error) {
      console.error("Error in fetching account contacts: " + error);
    } finally {
    }
  };
};

export const getAccountEmailReportLogs = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.ACCOUNT_REQUEST,
      });
      let accountEmailReportLogs = [];
      const response = await AccountApi.getAccountEmailReportLogs(props);
      if (response?.status == 200) {
        accountEmailReportLogs = {
          data: response?.data?.logs,
          account_name: response?.data?.account_name,
        };
      }
      return dispatch(
        accountAction.getAccountEmailReportLogs(accountEmailReportLogs)
      );
    } catch (error) {
      console.error("Error in fetching account email report logs: " + error);
    } finally {
    }
  };
};

export const getAccountNotifications = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.ACCOUNT_REQUEST,
      });
      let accountNotifications = [];
      const response = await AccountApi.getAccountNotifications(props);
      if (response?.status == 200) {
        accountNotifications = {
          data: response?.data?.notifications,
          account_name: response?.data?.account_name,
        };
      }
      return dispatch(
        accountAction.getAccountNotifications(accountNotifications)
      );
    } catch (error) {
      console.error("Error in fetching account notifications: " + error);
    } finally {
    }
  };
};

export const getAccountReports = (props, params = "") => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.ACCOUNT_REPORT_REQUEST,
      });
      let reports = {};
      const response = await AccountApi.getAccountReports(props, params);
      if (response?.status == 200) {
        reports = {
          data: response?.data?.data,
        };
      }
      return dispatch(accountAction.getAccountReports(reports));
    } catch (error) {
      console.error("Error in fetching accounts data: " + error);
    }
  };
};

export const getAccountAdvancedSetting = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.ACCOUNT_REQUEST,
      });
      let accountAdvancedSetting = {};
      const response = await AccountApi.getAccountAdvancedSetting(props);
      if (response?.status == 200) {
        accountAdvancedSetting = {
          data: response?.data?.data,
          services: response?.data?.service_list,
          currencies: response?.data?.currency_options,
          custom_reports: response?.data?.account_custom_reports,
          active_services: response?.data?.account_active_services,
          service_currency_values: response?.data?.service_currency_values,
          combined_fees_setting: response?.data?.combined_fees_setting,
          account_data: response?.data?.account,
        };
      }
      return dispatch(
        accountAction.getAccountAdvancedSetting(accountAdvancedSetting)
      );
    } catch (error) {
      console.error("Error in fetching account advanced setting: " + error);
    } finally {
    }
  };
};

export const getClientId = (props) => async (dispatch) => {
  try {
    const { account_id, report_key } = props;

    const clientdata = await AccountApi.getClientId({ account_id });
    const client_id = {
      data: clientdata?.data?.data?.client_id,
      currencies: clientdata?.data?.currencies,
    };
    dispatch(accountAction.getClientId(client_id));

    const adSpendData = await reportLayoutApi.getAdSpend({
      account_id,
    });

    const ad_spend = {
      ad_spend_status: adSpendData?.data[0]?.ad_spend,
      include_amazon_status: adSpendData?.data[1]?.include_amazon_data,
    };
    dispatch(reportLayoutAction.getAdSpend(ad_spend));

    // second
    const inputControllsData = await reportLayoutApi.getAllInputControls({
      report_key,
    });
    const check = inputControllsData?.data?.input_controls[0];
    const inputControls = JSON.parse(check?.input_controls);
    const report_layouts = {
      data_input_controls: inputControls,
    };

    dispatch(reportLayoutAction.getAllInputControls(report_layouts));

    // third
    const reportLayoutData = await reportLayoutApi.getReportLayouts({
      report_key,
      account_id,
    });
    const check2 = reportLayoutData?.data?.input_controls[0];
    const inputControls2 = JSON.parse(check2?.input_controls);
    const report_layouts2 = {
      data: reportLayoutData?.data,
      input_controls: inputControls2,
      data_layout_controls: reportLayoutData?.data?.layout_block,
      layout_controls: JSON.parse(reportLayoutData?.data?.layout[0]?.layout),
      layout_css: reportLayoutData?.data?.layout[0]?.custom_css,
    };
    dispatch(reportLayoutAction.getReportLayouts(report_layouts2));
  } catch (error) {
    console.error("Error in fetching client Id: " + error);
  }
};

export const getFreemiumClientId = (props) => async (dispatch) => {
  try {
    const { account_id, report_key } = props;

    const clientdata = await AccountApi.getClientId({ account_id });
    const client_id = {
      data: clientdata?.data?.data?.client_id,
    };
    dispatch(accountAction.getClientId(client_id));

    // const adSpendData = await reportLayoutApi.getAdSpend({
    //   account_id,
    // });
    // const ad_spend = {
    //   data: adSpendData?.data?.ad_spend,
    // };
    // dispatch(reportLayoutAction.getAdSpend(ad_spend));

    // second
    const inputControllsData = await reportLayoutApi.getAllInputControls({
      report_key,
    });
    const check = inputControllsData?.data?.input_controls[0];
    const inputControls = JSON.parse(check?.input_controls);
    const report_layouts = {
      data_input_controls: inputControls,
    };

    dispatch(reportLayoutAction.getAllInputControls(report_layouts));

    // third
    const reportLayoutData = await reportLayoutApi.getReportLayouts({
      report_key,
      account_id,
    });
    const check2 = reportLayoutData?.data?.input_controls[0];
    const inputControls2 = JSON.parse(check2?.input_controls);
    const report_layouts2 = {
      data: reportLayoutData?.data,
      input_controls: inputControls2,
      data_layout_controls: reportLayoutData?.data?.layout_block,
      layout_controls: JSON.parse(reportLayoutData?.data?.layout[0]?.layout),
      layout_css: reportLayoutData?.data?.layout[0]?.custom_css,
    };
    dispatch(reportLayoutAction.getReportLayouts(report_layouts2));
  } catch (error) {
    console.error("Error in fetching client Id: " + error);
  }
};

export const saveAccountAdvancedSetting = (props) => {
  let propsObj = JSON.parse(props);
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.ACCOUNT_REQUEST,
      });
      let accountAdvancedSetting = {};
      const response = await AccountApi.saveAccountAdvancedSetting(propsObj);
      if (response?.data?.data) {
        accountAdvancedSetting = { data: response?.data?.data };
      }
      if (response.status == 200) {
        message.success(response?.data?.message);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error("Oops, something went wrong.");
    } finally {
      return dispatch(
        getAccountAdvancedSetting({ account_id: propsObj.account_id })
      );
    }
  };
};


export const getAccountFeedsUrl = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.ACCOUNT_FETCH_FEEDS_REQUEST,
      });
      let accountDetails = [];
      const response = await AccountApi.getAccountFeedsUrl(props);
      if (response?.status == 200) {
        accountDetails = {
          data: response?.data?.data,
          feed_url: response?.data?.data?.feed_url
        };
      }
      return dispatch(accountAction.getAccountFeedsUrl(accountDetails));
    } catch (error) {
      console.error("Error in fetching account detail: " + error);
    } finally {
    }
  };
};
