export const initialState = {
  revenueRetentionData: [],
  lineChartData: [],
  isLoading: false,
};

export const ACTIONS_TYPES = Object.freeze({
  FETCH_REVENUE_RETENTION_DATA: "FETCH_REVENUE_RETENTION_DATA",
  GET_REVENUE_RETENTION_DATA: "GET_REVENUE_RETENTION_DATA",
  GET_LINE_CHART_DATA: "GET_LINE_CHART_DATA",
  FETCH_LINE_CHART_DATA: "FETCH_LINE_CHART_DATA",
});

export function LineChartReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.GET_REVENUE_RETENTION_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_REVENUE_RETENTION_DATA:
      return {
        ...state,
        isLoading: false,
        revenueRetentionData: action.payload,
      };
    case ACTIONS_TYPES.GET_LINE_CHART_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_LINE_CHART_DATA:
      return {
        ...state,
        isLoading: false,
        lineChartData: { ...state.lineChartData, ...action.payload },
      };
    default:
      return state;
  }
}
