import {
  BackTop,
  Button,
  Col,
  Form,
  Row,
  Switch,
  Tooltip,
  Spin,
  Modal,
  Collapse,
} from "antd";
import html2pdf from "html2pdf.js";
import moment from "moment";
import React, { useEffect, useRef, useState, useLayoutEffect } from "react";

import { FaInfoCircle, FaAngleUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../src/Utils/helpers";
import logo from "../../Assets/Images/logo.png";
import Config from "../../Config";
import { getAreaChartData } from "../../Redux/Actions/Thunk/AreaChartThunk";
import { getSessionData } from "../../Redux/Actions/Thunk/ColumnChartThunk";
import { getDonutChartData } from "../../Redux/Actions/Thunk/DonutThunk";
import { getRevenueRetentionData } from "../../Redux/Actions/Thunk/LineChartThunk";
import {
  getDevicePerformance,
  getTrendBreakdown,
  getTrendsData,
} from "../../Redux/Actions/Thunk/TrendThunk";
import {
  getHTML,
  getSliderData,
} from "../../Redux/Actions/Thunk/accountReportThunk";
import { getClientId } from "../../Redux/Actions/Thunk/accountThunk";
import {
  getBarGraphData,
  getStackedBarGraphData,
} from "../../Redux/Actions/Thunk/barGraphThunk";
import { getChannelPerformanceData } from "../../Redux/Actions/Thunk/channelPerformanceThunk";
import { getDataTable } from "../../Redux/Actions/Thunk/dataTableThunk";
import { getNormalTable } from "../../Redux/Actions/Thunk/normalTableThunk";
import { getPieChartData } from "../../Redux/Actions/Thunk/pieThunk";
import { getKeyMetrics } from "../../Redux/Actions/Thunk/reports";
import { getDataFromAPI } from "../../Redux/Actions/Thunk/normalTableThunk";
import { formDataAppend } from "../../Utils/helpers";
import PerformanceReport from "../ReportBlocks/MultiBlockComponents/PerformanceReport";
import BlockComponent from "./BlockComponent";
import InputControlComponent from "./InputControlComponent";
import { ACTIONS_TYPES } from "../../Redux/Reducers/accountReducer";
import CommentSettings from "../Common/CommentSettings";
import GlobalExport from "../Common/GlobalExport";
import Help from "../Common/Help";
import ReportComments from "../Common/ReportComments";
import { getTableSliderData } from "../../Redux/Actions/Thunk/tableSliderThunk";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  getAccountAndReports,
  setFilteredData,
} from "../../Redux/Actions/Thunk/reportLayoutThunk";
import { ACTIONS_TYPES as ReportActionTypes } from "../../Redux/Reducers/reportLayoutReducer";
import { getScatterData } from "../../Redux/Actions/Thunk/SactterThunk";
import { getMentionUsers } from "../../Api/annotationsCommentApi";
import Unauthorized from "../../Pages/Layout/Unauthorized";
import {
  getUserId,
  isCustomer,
  isIMSUser,
  isNonManagedCustomer,
  isSpecialist,
} from "../../Utils/user";
import { Token } from "../../Utils/helpers/token";

import { FaFilter } from "react-icons/fa";
import { PlusOutlined } from "@ant-design/icons";
import { getColumnLineData } from "../../Redux/Actions/Thunk/ColumnLineThunk";
import {
  setActiveTab,
  setActiveLineTab,
  setActiveTable,
} from "../../Redux/Actions/tabAction";

const Layout = (props) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [helpData, setHelpData] = useState("");
  const [triggerRerender, setTriggerRerender] = useState(false);
  const { account_id, report_key } = useParams();
  const pathArray = window?.location?.pathname?.split("/");
  const [data, setData] = useState([]);
  const [dateRangeCheck, setDateRangeCheck] = useState(false);
  const [persistDate, setDatePersist] = useState([]);
  const [filterDisplay, setfilterDisplay] = useState(false);
  var report_name = pathArray[4];
  var account_id_data = pathArray[5];
  // var report_name = report_key;
  // var account_id_data = account_id;
  var setValue = useRef(null);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const { Panel } = Collapse;
  //whether to show annotaion according to custom report key
  const custom_annotation_report_show = ["srp_holistic_report"];

  const {
    accounts: { client_id: { data: clientData = [] } = {} },
    reportLayouts: {
      input_controls: { data_input_controls: inputControlsData = [] } = {},
      ad_spend_data: {
        ad_spend_status: ad_spend_status = [],
        include_amazon_status: include_amazon_status = [],
      } = {},
      layouts: {
        data: layoutData = [],
        data_layout_controls: dataLayout = [],
        layout_controls: controlLayout = [],
        layout_css: custom_report_css = [],
        // input_controls: inputControlsData = [],
      } = {},
      account_reports_data: {
        reports: reportData = [],
        accounts: accountsData = [],
      } = {},
      isLoading,
    } = {},
  } = useSelector((state) => state);
  const { reportLayouts, accountDataSources } = useSelector((state) => state);
  const dispatch = useDispatch();
  // Fetch data
  let defaultFilterData = {};
  useEffect(() => {
    Promise.all([
      dispatch(
        getClientId({ account_id: account_id_data, report_key: report_name })
      ),
      dispatch(
        getAccountAndReports({
          account_id: account_id_data,
          report_key: report_name,
        })
      ),
    ]);
  }, [account_id_data, report_name, window.localStorage]);

  const account = accountsData.find(
    (account) => account.account_id === account_id
  );

  if (account !== undefined) {
    var account_name = account.account_name;
  } else {
    var account_name = "";
  }
  const reports = reportData.find(
    (reports) => reports.report_key === report_name
  );

  if (reports !== undefined) {
    var reportsName = reports.name;
  } else {
    var reportsName = "";
  }

  if (
    layoutData !== undefined &&
    layoutData.length != 0 &&
    layoutData.layout.length > 0
  ) {
    var service_id = layoutData?.layout[0]?.service_id;
  } else {
    var service_id = "";
  }

  useEffect(() => {
    dispatch({
      type: ReportActionTypes.RESET_STATE,
    });
  }, []);

  const storedArray = localStorage.getItem("datePersistVal");
  const datePersistVal = JSON.parse(storedArray);
  //add mention
  const [atValues, setAtValues] = useState([]);
  const [isMountRender, setMountRender] = useState(true);
  const handleMentionUser = async (e) => {
    try {
      const response = await getMentionUsers({
        account_id: account_id,
      });

      const mentionUsers = response?.data?.mention_users;

      const formattedMentionUsers = mentionUsers.map((user, index) => ({
        id: user.id,
        value: user.value,
        email: user.email,
      }));
      setAtValues(formattedMentionUsers);
      // setMountRender(false);
    } catch (err) {
      // Handle exceptions and errors
    }
  };
  useEffect(() => {
    if (isMountRender === true) {
      setMountRender(false);
      handleMentionUser();
    }
  }, [isMountRender]);

  inputControlsData.forEach((element, i) => {
    if (
      element.type == "daterangepicker" ||
      element.type == "monthrangepicker" ||
      element.type == "date"
    ) {
      var presetRanges = element.presetRanges;
      var additionalRanges = element.additionalPresetRanges;
      var dateFormat = element.format;
      var defaultDateRange = element.default_date_range_option
        ? capitalizeFirstLetter(
            element.default_date_range_option.replaceAll("_", " ")
          )
        : "Last Month";
      var defaultCompareDateRange = element.default_compare_date_range_option
        ? element.default_compare_date_range_option
        : "Same Period Last Year";

      if (element.default_date_range_option == "this_month") {
        if (moment().format("D") === "1") {
          var date = [moment().startOf("month"), moment()];
        } else {
          var date = [moment().startOf("month"), moment().subtract(1, "day")];
        }
        var fromDate = moment(date[0]);
        var toDate = moment(date[1]);

        var compareDate = [
          fromDate.subtract(1, "year"),
          toDate.subtract(1, "year"),
        ];
      } else if (element.default_date_range_option == "last_month") {
        var date = [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ];
        var fromDate = moment(date[0]);
        var toDate = moment(date[1]);

        var compareDate = [
          fromDate.subtract(1, "year"),
          toDate.subtract(1, "year"),
        ];
      } else if (element.default_date_range_option == "last_365_days") {
        var date = [
          moment().subtract(365, "days"),
          moment().subtract(1, "days"),
        ];
        var fromDate = moment(date[0]);
        var toDate = moment(date[1]);

        var compareDate = [
          fromDate.subtract(1, "year"),
          toDate.subtract(1, "year"),
        ];
      } else if (
        typeof JSON.parse(element.default_date_range_option) == "object" &&
        JSON.parse(element.default_date_range_option)[0]["startdate"] ===
          "today"
      ) {
        var subDays = JSON.parse(element.default_date_range_option)[1][
          "subDays"
        ];
        if (subDays == 30) {
          if (
            JSON.parse(element.default_date_range_option)[2]["toDate"] ===
            "today"
          ) {
            var date = [moment().subtract(subDays, "day"), moment()];
          } else {
            var date = [
              moment().subtract(subDays, "day"),
              moment().subtract(1, "day"),
            ];
          }
        } else {
          var date = [
            moment().startOf("month"),
            moment().subtract(subDays, "day"),
          ];
        }

        var fromDate = moment(date[0]);
        var toDate = moment(date[1]);
        var defaultDateRange = "";

        var compareDate = [
          fromDate.subtract(1, "year"),
          toDate.subtract(1, "year"),
        ];
      } else {
        var date = [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ];
        var fromDate = moment(date[0]);
        var toDate = moment(date[1]);

        var compareDate = [
          fromDate.subtract(1, "year"),
          toDate.subtract(1, "year"),
        ];
      }
      if (
        element.component_type == "inputDatePicker" ||
        element.id == "created_date_range"
      ) {
        if (element.id == "created_date_range") {
          var dateFormat1 = "YYYY-MM-DD";
        }
        if (element.default_date_range_option == "this_month") {
          var date = moment().startOf("month");
          var compareDate = moment(date).subtract(1, "year").startOf("month");
          var from_date = date.format(dateFormat);
          var to_date = compareDate.format(dateFormat);
          var fDate = date.format(dateFormat).replaceAll("-", "");
          var tDate = compareDate.format(dateFormat).replaceAll("-", "");
          var from_created_date = "";
          var to_created_date = "";
          var from_date_compare = "";
          var to_date_compare = "";
          var fCDate = "";
          var tCDate = "";
        } else if (element.default_date_range_option == "last_month") {
          var date = moment().startOf("month").subtract(1, "month");
          var compareDate = moment(date).subtract(1, "year").startOf("month");
          var from_date = date.format(dateFormat);
          var to_date = compareDate.format(dateFormat);
          var fDate = date.format(dateFormat).replaceAll("-", "");
          var tDate = compareDate.format(dateFormat).replaceAll("-", "");
          var from_created_date = "";
          var to_created_date = "";
          var from_created_date = "";
          var to_created_date = "";
          var from_date_compare = "";
          var to_date_compare = "";
          var fCDate = "";
          var tCDate = "";
        }
      } else {
        var from_date = date[0].format(dateFormat);
        var to_date = date[1].format(dateFormat);
        var fDate = date[0].format(dateFormat).replaceAll("-", "");
        var tDate = date[1].format(dateFormat).replaceAll("-", "");
        var from_date_compare = compareDate[0].format(dateFormat);
        var to_date_compare = compareDate[1].format(dateFormat);
        var fCDate = compareDate[0].format(dateFormat).replaceAll("-", "");
        var tCDate = compareDate[1].format(dateFormat).replaceAll("-", "");
      }

      if (
        element.component_type == "customrangepicker" &&
        !element.hasOwnProperty("view_type")
      ) {
        var dateFormat = element.format;
        var from_date = date[1].format(dateFormat);
        var to_date = date[1].format(dateFormat);
        var fDate = date[1].format(dateFormat).replaceAll("-", "");
        var tDate = date[1].format(dateFormat).replaceAll("-", "");
      }
      defaultFilterData["date_range_type"] = element.type;
      defaultFilterData["component_type"] = element.component_type;
      defaultFilterData["from_date"] = from_date;
      defaultFilterData["to_date"] = to_date;
      defaultFilterData["from_date_compare"] = from_date_compare;
      defaultFilterData["to_date_compare"] = to_date_compare;
      defaultFilterData["date_range_option"] = defaultDateRange;
      defaultFilterData["date_month_option"] = defaultDateRange;
      defaultFilterData["f_date"] = fDate;
      defaultFilterData["t_date"] = tDate;
      defaultFilterData["c_f_date"] = fCDate;
      defaultFilterData["c_t_date"] = tCDate;
      defaultFilterData["date_range_compareto"] = defaultCompareDateRange;
      defaultFilterData["date_month_compareto"] = defaultCompareDateRange;
      defaultFilterData["from_month"] = from_date;
      defaultFilterData["to_month"] = to_date;
      defaultFilterData["from_month_compare"] = from_date_compare;
      defaultFilterData["to_month_compare"] = to_date_compare;
      defaultFilterData["f_month"] = fDate;
      defaultFilterData["t_month"] = tDate;
      defaultFilterData["run_date"] = to_date;
      defaultFilterData["c_f_month"] = fCDate;
      defaultFilterData["c_t_month"] = tCDate;
      defaultFilterData["from_created_date"] =
        from_created_date != undefined ? from_created_date : "";
      defaultFilterData["to_created_date"] =
        to_created_date != undefined ? to_created_date : "";
      defaultFilterData["month"] = moment(fDate).format("YYYY-MM");
      defaultFilterData["app_date"] = moment().format("YYYY-MM-DD");
    }
    defaultFilterData["authentication"] = Token.encryptAuthParams(
      window.localStorage.getItem("token")
    );
    defaultFilterData["Client"] = clientData;
    defaultFilterData["account_id"] = account_id_data;
    defaultFilterData[element.param_name] =
      element.default != undefined ? element.default : "";
    if (
      element.default == "%%current_user_id_ims%%" ||
      element.default == "%%current_user_id_specialist%%"
    ) {
      if (isIMSUser() || isSpecialist()) {
        defaultFilterData[element.param_name] = getUserId();
      } else {
        defaultFilterData[element.param_name] = "-1";
      }
    }
    defaultFilterData["ad_spend_status"] = ad_spend_status;
    defaultFilterData["include_amazon_data"] = include_amazon_status;
    defaultFilterData["by_pass_cache"] = "no";
    defaultFilterData["offset"] = 0;
    defaultFilterData["service_id"] = service_id;
    // defaultFilterData["limit"] = 50;
    // defaultFilterData["length"] = 50;
    Object.keys(params).map((el) => {
      defaultFilterData[el] = params[el];
    });

    if (
      element.id == "created_date_range" &&
      element.component_type == "customrangepicker" &&
      datePersistVal != null &&
      datePersistVal["component_type"] == "inputDatePicker"
    ) {
      Object.keys(datePersistVal).map((el) => {
        defaultFilterData[el] = datePersistVal[el];
      });
    }
    if (
      datePersistVal != null &&
      Object.keys(params).length == 0 &&
      datePersistVal["component_type"] == element.component_type
    ) {
      if (
        presetRanges == undefined &&
        element.id != "created_date_range" &&
        (element.component_type == "customrangepicker" ||
          element.component_type == "multiplecustomdatepicker")
      ) {
        // Object.keys(datePersistVal).map((el) => {
        //   defaultFilterData[el] = datePersistVal[el];
        // });
      } else if (
        (element.type == "daterangepicker" ||
          element.type == "monthrangepicker" ||
          element.type == "date") &&
        dateFormat === datePersistVal["dateFormat"]
      ) {
        var presetRanges = element.presetRanges;
        var additionalRanges = element.additionalPresetRanges;
        if (
          presetRanges == undefined &&
          datePersistVal["date_range_option"] == "Custom"
        ) {
          Object.keys(datePersistVal).map((el) => {
            defaultFilterData[el] = datePersistVal[el];
          });
        } else if (
          presetRanges != undefined &&
          additionalRanges != undefined &&
          presetRanges.includes(datePersistVal["date_range_option"]) &&
          datePersistVal["additional_persist_label"] == "None"
        ) {
          Object.keys(datePersistVal).map((el) => {
            defaultFilterData[el] = datePersistVal[el];
          });
        } else if (
          presetRanges != undefined &&
          additionalRanges != undefined &&
          presetRanges.includes(datePersistVal["date_range_option"])
        ) {
          Object.keys(datePersistVal).map((el) => {
            defaultFilterData[el] = datePersistVal[el];
          });
        } else if (
          presetRanges != undefined &&
          dateFormat === datePersistVal["dateFormat"] &&
          presetRanges.includes(datePersistVal["date_range_option"])
        ) {
          Object.keys(datePersistVal).map((el) => {
            defaultFilterData[el] = datePersistVal[el];
          });
        }
      }
    }
  });

  const handle_drill_input_control = () => {
    inputControlsData.forEach((element, i) => {
      if (
        (element.type == "daterangepicker" ||
          element.type == "monthrangepicker" ||
          element.type == "date") &&
        datePersistVal != null
      ) {
        var dateFormat = element.format;
        var defaultDateRange = element.default_date_range_option
          ? capitalizeFirstLetter(
              element.default_date_range_option.replaceAll("_", " ")
            )
          : "Last Month";
        var defaultCompareDateRange = element.default_compare_date_range_option
          ? element.default_compare_date_range_option
          : "Same Period Last Year";

        if (element.default_date_range_option == "this_month") {
          if (moment().format("D") === "1") {
            var date = [moment(), moment()];
          } else {
            var date = [moment().startOf("month"), moment().subtract(1, "day")];
          }
          var fromDate = moment(date[0]);
          var toDate = moment(date[1]);

          var compareDate = [
            fromDate.subtract(1, "year"),
            toDate.subtract(1, "year"),
          ];
        } else if (element.default_date_range_option == "last_month") {
          var date = [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ];
          var fromDate = moment(date[0]);
          var toDate = moment(date[1]);

          var compareDate = [
            fromDate.subtract(1, "year"),
            toDate.subtract(1, "year"),
          ];
        } else if (element.default_date_range_option == "last_365_days") {
          var date = [
            moment().subtract(365, "days"),
            moment().subtract(1, "days"),
          ];
          var fromDate = moment(date[0]);
          var toDate = moment(date[1]);

          var compareDate = [
            fromDate.subtract(1, "year"),
            toDate.subtract(1, "year"),
          ];
        } else if (
          typeof JSON.parse(element.default_date_range_option) == "object" &&
          JSON.parse(element.default_date_range_option)[0]["startdate"] ===
            "today"
        ) {
          var subDays = JSON.parse(element.default_date_range_option)[1][
            "subDays"
          ];
          if (subDays == 30) {
            if (
              JSON.parse(element.default_date_range_option)[2]["toDate"] ===
              "today"
            ) {
              var date = [moment().subtract(subDays, "day"), moment()];
            } else {
              var date = [
                moment().subtract(subDays, "day"),
                moment().subtract(1, "day"),
              ];
            }
          } else {
            var date = [
              moment().startOf("month"),
              moment().subtract(subDays, "day"),
            ];
          }

          var fromDate = moment(date[0]);
          var toDate = moment(date[1]);
          var defaultDateRange = "";

          var compareDate = [
            fromDate.subtract(1, "year"),
            toDate.subtract(1, "year"),
          ];
        } else {
          var date = [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ];
          var fromDate = moment(date[0]);
          var toDate = moment(date[1]);

          var compareDate = [
            fromDate.subtract(1, "year"),
            toDate.subtract(1, "year"),
          ];
        }
        if (
          element.component_type == "inputDatePicker" ||
          element.id == "created_date_range"
        ) {
          if (element.id == "created_date_range") {
            var dateFormat1 = "YYYY-MM-DD";
          }
          if (element.default_date_range_option == "this_month") {
            var date = moment().startOf("month");
            var compareDate = moment(date).subtract(1, "year").startOf("month");
            var from_date = date.format(dateFormat);
            var to_date = compareDate.format(dateFormat);
            var fDate = date.format(dateFormat).replaceAll("-", "");
            var tDate = compareDate.format(dateFormat).replaceAll("-", "");
            var from_created_date = "";
            var to_created_date = "";
            var from_created_date = "";
            var to_created_date = "";
            var from_date_compare = "";
            var to_date_compare = "";
            var fCDate = "";
            var tCDate = "";
          } else if (element.default_date_range_option == "last_month") {
            var date = moment().startOf("month").subtract(1, "month");
            var compareDate = moment(date).subtract(1, "year").startOf("month");
            var from_date = date.format(dateFormat);
            var to_date = compareDate.format(dateFormat);
            var fDate = date.format(dateFormat).replaceAll("-", "");
            var tDate = compareDate.format(dateFormat).replaceAll("-", "");
            var from_created_date = "";
            var to_created_date = "";
            var from_created_date = "";
            var to_created_date = "";
            var from_date_compare = "";
            var to_date_compare = "";
            var fCDate = "";
            var tCDate = "";
          }
        } else {
          var from_date = date[0].format(dateFormat);
          var to_date = date[1].format(dateFormat);
          var fDate = date[0].format(dateFormat).replaceAll("-", "");
          var tDate = date[1].format(dateFormat).replaceAll("-", "");
          var from_date_compare = compareDate[0].format(dateFormat);
          var to_date_compare = compareDate[1].format(dateFormat);
          var fCDate = compareDate[0].format(dateFormat).replaceAll("-", "");
          var tCDate = compareDate[1].format(dateFormat).replaceAll("-", "");
        }

        if (
          element.component_type == "customrangepicker" &&
          !element.hasOwnProperty("view_type")
        ) {
          var dateFormat = element.format;
          var from_date = date[1].format(dateFormat);
          var to_date = date[1].format(dateFormat);
          var fDate = date[1].format(dateFormat).replaceAll("-", "");
          var tDate = date[1].format(dateFormat).replaceAll("-", "");
        }
        defaultFilterData["date_range_type"] = element.type;
        defaultFilterData["component_type"] = element.component_type;
        defaultFilterData["from_date"] = from_date;
        defaultFilterData["to_date"] = to_date;
        defaultFilterData["from_date_compare"] = from_date_compare;
        defaultFilterData["to_date_compare"] = to_date_compare;
        defaultFilterData["date_range_option"] = defaultDateRange;
        defaultFilterData["date_month_option"] = defaultDateRange;
        defaultFilterData["f_date"] = fDate;
        defaultFilterData["t_date"] = tDate;
        defaultFilterData["c_f_date"] = fCDate;
        defaultFilterData["c_t_date"] = tCDate;
        defaultFilterData["date_range_compareto"] = defaultCompareDateRange;
        defaultFilterData["date_month_compareto"] = defaultCompareDateRange;
        defaultFilterData["from_month"] = from_date;
        defaultFilterData["to_month"] = to_date;
        defaultFilterData["from_month_compare"] = from_date_compare;
        defaultFilterData["to_month_compare"] = to_date_compare;
        defaultFilterData["f_month"] = fDate;
        defaultFilterData["t_month"] = tDate;
        defaultFilterData["run_date"] = to_date;
        defaultFilterData["c_f_month"] = fCDate;
        defaultFilterData["c_t_month"] = tCDate;
        defaultFilterData["from_created_date"] =
          from_created_date != undefined ? from_created_date : "";
        defaultFilterData["to_created_date"] =
          to_created_date != undefined ? to_created_date : "";
        defaultFilterData["month"] = moment(fDate).format("YYYY-MM");
        defaultFilterData["app_date"] = moment().format("YYYY-MM-DD");
      }
      defaultFilterData["authentication"] = Token.encryptAuthParams(
        window.localStorage.getItem("token")
      );
      defaultFilterData["Client"] = clientData;
      defaultFilterData["account_id"] = account_id_data;
      defaultFilterData[element.param_name] =
        element.default != undefined ? element.default : "";
      if (
        element.default == "%%current_user_id_ims%%" ||
        element.default == "%%current_user_id_specialist%%"
      ) {
        if (isIMSUser() || isSpecialist()) {
          defaultFilterData[element.param_name] = getUserId();
        } else {
          defaultFilterData[element.param_name] = "-1";
        }
      }
      defaultFilterData["ad_spend_status"] = ad_spend_status;
      defaultFilterData["include_amazon_data"] = include_amazon_status;

      defaultFilterData["by_pass_cache"] = "no";
      defaultFilterData["offset"] = 0;
      // defaultFilterData["limit"] = 50;
      defaultFilterData["service_id"] = service_id;
      // defaultFilterData["length"] = 50;
      Object.keys(params).forEach((el) => {
        defaultFilterData[el] = params[el];
      });
    });
  };

  useEffect(() => {
    handle_drill_input_control();
  }, [params]);

  var formDatafilter = "";

  let filterData = {};
  let dateParams = {};
  const report_id = reportLayouts?.layouts?.data?.layout[0].id;
  const [fromDateChange, setFromDateChange] = useState(null);
  const [toDateChange, setToDateChange] = useState(null);

  const onFilterChanges = (value) => {
    localStorage.setItem("goal_params", false);
    if (
      value.hasOwnProperty("dateRange") ||
      value.hasOwnProperty("dateSRange") ||
      value.hasOwnProperty("dateRange1")
    ) {
      var dateFormat = value.dateFormat;
      var dateRange = value.dateRange;

      if (
        value.hasOwnProperty("CustomDateRange") &&
        value.hasOwnProperty("dateRange") &&
        value.hasOwnProperty("dateSRange")
      ) {
        var date = value.CustomDateRange;
        var dateSRange = value.dateSRange;
        var from_date = dateRange.format(dateFormat);
        var to_date = dateSRange.format(dateFormat);
        var fDate = dateRange.format(dateFormat).replaceAll("-", "");
        var tDate = dateSRange.format(dateFormat).replaceAll("-", "");
        var from_date_compare = "";
        var to_date_compare = "";
        var fCDate = "";
        var tCDate = "";
        if (date[0] != "" && date[1] != "") {
          var from_created_date = date[0].format("YYYY-MM-DD");
          var to_created_date = date[1].format("YYYY-MM-DD");
        }
      } else if (
        value.hasOwnProperty("dateRange1") &&
        value.hasOwnProperty("customDate") &&
        value.customDate == "toDate"
      ) {
        var dateRange1 = value.dateRange1;
        var from_date = dateRange1.format(dateFormat);
        var to_date = dateRange1.format(dateFormat);
        var fDate = dateRange1.format(dateFormat).replaceAll("-", "");
        var tDate = dateRange1.format(dateFormat).replaceAll("-", "");
        var from_date_compare = "";
        var to_date_compare = "";
        var fCDate = "";
        var tCDate = "";
      } else if (value.hasOwnProperty("CustomDateRange")) {
        var date = value.CustomDateRange;
        var from_date = date[0].format(dateFormat);
        var to_date = date[1].format(dateFormat);
        var fDate = date[0].format(dateFormat).replaceAll("-", "");
        var tDate = date[1].format(dateFormat).replaceAll("-", "");
        var from_date_compare = "";
        var to_date_compare = "";
        var fCDate = "";
        var tCDate = "";
      } else if (value.hasOwnProperty("dateType")) {
        var dateSRange = value.dateSRange;
        var from_date = dateRange.format(dateFormat);
        var to_date = dateSRange.format(dateFormat);
        var fDate = dateRange.format(dateFormat).replaceAll("-", "");
        var tDate = dateSRange.format(dateFormat).replaceAll("-", "");
        var from_date_compare = "";
        var to_date_compare = "";
        var fCDate = "";
        var tCDate = "";
      } else if (
        value.hasOwnProperty("dateRange") &&
        value.dateRange.hasOwnProperty("fromDate") &&
        value.dateRange.hasOwnProperty("toDate")
      ) {
        var from_date = value.dateRange.fromDate.format(dateFormat);
        var to_date = value.dateRange.toDate.format(dateFormat);
        var fDate = value.dateRange.fromDate
          .format(dateFormat)
          .replaceAll("-", "");
        var tDate = value.dateRange.toDate
          .format(dateFormat)
          .replaceAll("-", "");
        var from_date_compare = "";
        var to_date_compare = "";
        var fCDate = "";
        var tCDate = "";
      } else {
        if (value.hasOwnProperty("dateSRange")) {
          var dateSRange = value.dateSRange;
          var from_date_compare = dateSRange[0].format(dateFormat);
          var to_date_compare = dateSRange[1].format(dateFormat);
          var fCDate = dateSRange[0].format(dateFormat).replaceAll("-", "");
          var tCDate = dateSRange[1].format(dateFormat).replaceAll("-", "");
        }
        var from_date = dateRange[0].format(dateFormat);
        var to_date = dateRange[1].format(dateFormat);
        var fDate = dateRange[0].format(dateFormat).replaceAll("-", "");
        var tDate = dateRange[1].format(dateFormat).replaceAll("-", "");
      }
    }

    inputControlsData.forEach((element, i) => {
      filterData["authentication"] = Token.encryptAuthParams(
        window.localStorage.getItem("token")
      );
      filterData["Client"] = clientData;
      filterData["account_id"] = account_id_data;
      filterData[element.param_name] =
        element.default != undefined ? element.default : "";
      if (
        element.type == "daterangepicker" ||
        element.type == "monthrangepicker" ||
        element.type == "date"
      ) {
        filterData["date_range_type"] =
          element.type == "daterangepicker"
            ? "daterangepicker"
            : "monthrangepicker";
        filterData["from_month"] = from_date;
        filterData["to_month"] = to_date;
        filterData["component_type"] = element.component_type;

        filterData["f_month"] = fDate;
        filterData["t_month"] = tDate;

        if (
          value.hasOwnProperty("selectedVal") &&
          value.selectedVal.current == "last_30_days"
        ) {
          filterData["is_30days"] = 1;
        } else {
          filterData["is_30days"] = 0;
        }

        if (value.hasOwnProperty("selectedVal")) {
          filterData["month_range_option"] = capitalizeFirstLetter(
            value.selectedVal.current.replaceAll("_", " ")
          );
          filterData["date_range_option"] = capitalizeFirstLetter(
            value.selectedVal.current.replaceAll("_", " ")
          );
          filterData["date_month_option"] = capitalizeFirstLetter(
            value.selectedVal.current.replaceAll("_", " ")
          );
          filterData["persist_label"] = value.selectedVal.current;
          dateParams["date_range_option"] = capitalizeFirstLetter(
            value.selectedVal.current.replaceAll("_", " ")
          );
          dateParams["month_range_option"] = capitalizeFirstLetter(
            value.selectedVal.current.replaceAll("_", " ")
          );
        }

        if (
          value.hasOwnProperty("dateSRange") &&
          value.hasOwnProperty("selectedAdditionalVal") &&
          value.selectedAdditionalVal.current == "Same 30 Days Last Year"
        ) {
          filterData["compare_prev_year_30days"] = 1;
        } else {
          filterData["compare_prev_year_30days"] = 0;
        }

        if (
          element.component_type != "multiplecustomdatepicker" &&
          element.component_type != "customrangepicker" &&
          value.hasOwnProperty("selectedVal")
        ) {
          dateParams["persist_label"] = value.selectedVal.current;
          dateParams["date_range_option"] = capitalizeFirstLetter(
            value.selectedVal.current.replaceAll("_", " ")
          );
          dateParams["month_range_option"] = capitalizeFirstLetter(
            value.selectedVal.current.replaceAll("_", " ")
          );
        }

        filterData["from_date"] = from_date;
        filterData["to_date"] = to_date;
        filterData["by_pass_cache"] = "yes";

        filterData["f_date"] = fDate;
        filterData["t_date"] = tDate;
        filterData["run_date"] = to_date;

        if (value.hasOwnProperty("selectedAdditionalVal")) {
          filterData["date_range_compareto"] =
            value.selectedAdditionalVal.current;

          filterData["month_range_compareto"] =
            value.selectedAdditionalVal.current;

          filterData["date_month_compareto"] =
            value.selectedAdditionalVal.current;
          filterData["additional_persist_label"] =
            value.selectedAdditionalVal.current;
          if (value.hasOwnProperty("dateSRange")) {
            filterData["from_date_compare"] = from_date_compare;
            filterData["to_date_compare"] = to_date_compare;
            filterData["from_month_compare"] = from_date_compare;
            filterData["to_month_compare"] = to_date_compare;
            filterData["c_f_month"] = fCDate;
            filterData["c_t_month"] = tCDate;
            filterData["c_f_date"] = fCDate;
            filterData["c_t_date"] = tCDate;
            dateParams["c_f_month"] = fCDate;
            dateParams["c_t_month"] = tCDate;
            dateParams["c_f_date"] = fCDate;
            dateParams["c_t_date"] = tCDate;
            dateParams["from_date_compare"] = from_date_compare;
            dateParams["to_date_compare"] = to_date_compare;
            dateParams["from_month_compare"] = from_date_compare;
            dateParams["to_month_compare"] = to_date_compare;
          }
        }

        if (
          element.component_type != "multiplecustomdatepicker" &&
          element.component_type != "customrangepicker" &&
          value.hasOwnProperty("selectedAdditionalVal")
        ) {
          dateParams["additional_persist_label"] =
            value.selectedAdditionalVal.current;
        }

        if (
          element.component_type != "multiplecustomdatepicker" &&
          element.component_type != "customrangepicker" &&
          value.hasOwnProperty("dateRange") &&
          value.hasOwnProperty("selectedAdditionalVal") &&
          value.selectedAdditionalVal.current == "None"
        ) {
          dateParams["additional_persist_label"] =
            value.selectedAdditionalVal.current;
          filterData["date_range_compareto"] =
            value.selectedAdditionalVal.current;

          filterData["month_range_compareto"] =
            value.selectedAdditionalVal.current;
          filterData["date_month_compareto"] =
            value.selectedAdditionalVal.current;

          dateParams["month_range_compareto"] =
            value.selectedAdditionalVal.current;
          dateParams["date_range_compareto"] =
            value.selectedAdditionalVal.current;
          dateParams["date_month_compareto"] =
            value.selectedAdditionalVal.current;

          filterData["from_date_compare"] = moment().format(dateFormat);
          filterData["to_date_compare"] = moment().format(dateFormat);
          filterData["from_month_compare"] = moment().format(dateFormat);
          filterData["to_month_compare"] = moment().format(dateFormat);
          dateParams["from_date_compare"] = moment().format(dateFormat);
          dateParams["to_date_compare"] = moment().format(dateFormat);
          dateParams["from_month_compare"] = moment().format(dateFormat);
          dateParams["to_month_compare"] = moment().format(dateFormat);
        }

        dateParams["from_date"] = from_date;
        dateParams["to_date"] = to_date;
        dateParams["f_date"] = fDate;
        dateParams["t_date"] = tDate;
        dateParams["from_month"] = from_date;
        dateParams["to_month"] = to_date;
        dateParams["component_type"] = element.component_type;
        dateParams["f_month"] = fDate;
        dateParams["t_month"] = tDate;
        dateParams["month"] = moment(fDate).format("YYYY-MM");
        dateParams["dateFormat"] = dateFormat;

        if (
          element.component_type != "customrangepicker" &&
          element.component_type != "multiplecustomdatepicker" &&
          (value.hasOwnProperty("dateRange") ||
            value.hasOwnProperty("dateSRange") ||
            value.hasOwnProperty("dateRange1"))
        ) {
          localStorage.setItem("datePersistVal", JSON.stringify(dateParams));
        }
      }
      filterData["from_created_date"] =
        from_created_date != undefined ? from_created_date : "";
      filterData["to_created_date"] =
        to_created_date != undefined ? to_created_date : "";
      filterData["by_pass_cache"] = "yes";

      if (value[element.param_name] == undefined) {
        value[element.param_name] =
          element.default != undefined ? element.default : "";
      }
      if (value[element.param_name] == "%%current_user_id_ims%%") {
        value[element.param_name] = "-1";
      }
      if (value[element.param_name] == "%%current_user_id_specialist%%") {
        value[element.param_name] = "-1";
      }
    });

    filterData["app_date"] = moment().format("YYYY-MM-DD");
    filterData["month"] = moment(fDate).format("YYYY-MM");
    filterData["include_amazon_data"] = include_amazon_status;
    filterData["ad_spend_status"] = ad_spend_status;
    filterData["offset"] = 0;
    // filterData["limit"] = 50;
    filterData["service_id"] = service_id;

    var changedFilter = { ...filterData, ...value };
    // layoutData?.layout_block?.map((block) => {
    //   if (
    //     block.component == "DataTable" ||
    //     block.component == "DataTableClient"
    //   ) {
    //     const block_data = JSON.parse(block?.config);
    //     changedFilter["order_by"] = block_data["defaultOrder"]
    //       ? block_data["defaultOrder"]
    //       : "";
    //     changedFilter["order_column"] = block_data["defaultOrderColumn"]
    //       ? block_data["defaultOrderColumn"]
    //       : "";
    //     changedFilter["limit"] = block_data["limit"]
    //       ? parseInt(block_data["limit"])
    //       : 50;
    //   }
    // });
    setData(changedFilter);

    formDatafilter = formDataAppend(changedFilter);
    {
      layoutData?.layout_block?.map((block) => {
        if (block?.component === "DataTable") {
          formDatafilter.set("limit", JSON.parse(block?.config)?.limit);
        }
        if (block?.component === "StackedChart") {
          formDatafilter.set("view_mode", "Monthly");
          dispatch(setActiveTab("Monthly"));
        }
        if (block?.component === "LineChart") {
          formDatafilter.set("view_mode", "Monthly");
          dispatch(setActiveLineTab("Monthly"));
        }
        if (block?.component === "SplineBar") {
          formDatafilter.set("view_mode", "Monthly");
          dispatch(setActiveTab("Monthly"));
        }
        if (
          block?.component === "DataTableClient" ||
          block?.component === "DataTable" ||
          block.component == "clientDataTable"
        ) {
          formDatafilter.set("email_sms_data", 0);
          dispatch(setActiveTable(0));
        }

        return block.component == "KeyMetrics"
          ? dispatch(getKeyMetrics(formDatafilter, { block: block }))
          : block.component == "SplineBar"
          ? dispatch(getTrendsData(formDatafilter, { block: block }))
          : block.component == "TrendBreakdown"
          ? dispatch(getTrendBreakdown(formDatafilter, { block: block }))
          : block.component == "DevicePerformance"
          ? dispatch(getDevicePerformance(formDatafilter, { block: block }))
          : block.component == "CollapsibleTable"
          ? dispatch(getNormalTable(formDatafilter, { block: block }))
          : block.component == "PerformanceReport"
          ? PerformanceReport(formDatafilter, { block: block })
          : block.component == "ColumnCharts"
          ? dispatch(getSessionData(formDatafilter, { block: block }))
          : block.component == "BarChart"
          ? dispatch(getBarGraphData(formDatafilter, { block: block }))
          : block.component == "DataTable"
          ? dispatch(getDataTable(formDatafilter, { block: block }))
          : block.component == "DataTableClient" ||
            block.component == "clientDataTable"
          ? dispatch(getDataTable(formDatafilter, { block: block }))
          : block.component == "StackedBar"
          ? dispatch(getStackedBarGraphData(formDatafilter, { block: block }))
          : block.component == "LineChart"
          ? dispatch(getRevenueRetentionData(formDatafilter, { block: block }))
          : block.component == "Slider"
          ? dispatch(getSliderData(formDatafilter, { block: block }))
          : block.component == "StackedChart"
          ? dispatch(getBarGraphData(formDatafilter, { block: block }))
          : block.component == "PieChart"
          ? dispatch(getPieChartData(formDatafilter, { block: block }))
          : block.component == "DonutChart"
          ? dispatch(getDonutChartData(formDatafilter, { block: block }))
          : block.component == "StackAreaChart"
          ? dispatch(getAreaChartData(formDatafilter, { block: block }))
          : block.component == "ChannelPerformance"
          ? dispatch(
              getChannelPerformanceData(formDatafilter, { block: block })
            )
          : block.component == "HTMLRender"
          ? dispatch(getHTML(formDatafilter, { block: block }))
          : block.component == "CLVGoal"
          ? dispatch(getTableSliderData(formDatafilter, { block: block }))
          : block.component == "CampaignCollapsibleTable"
          ? dispatch(getDevicePerformance(formDatafilter, { block: block }))
          : block.component == "EditableDataTable"
          ? dispatch(getDataTable(formDatafilter, { block: block }))
          : block.component == "Slider"
          ? dispatch(getSliderData(formDatafilter, { block: block }))
          : block.component == "Scatter"
          ? dispatch(getScatterData(formDatafilter, { block: block }))
          : block.component == "SEOProjectStatus"
          ? dispatch(getSessionData(formDatafilter, { block: block }))
          : block.component == "CampaignPerformance"
          ? dispatch(getDevicePerformance(formDatafilter, { block: block }))
          : block.component == "PlacementPerformance"
          ? dispatch(getDevicePerformance(formDatafilter, { block: block }))
          : block.component == "HTMLRender"
          ? dispatch(getHTML(formDatafilter, { block: block }))
          : block.component == "NormalTableWithColGroup"
          ? dispatch(getDataFromAPI(formDatafilter, { block: block }))
          : block.component == "CustomChannelPerformance"
          ? dispatch(
              getChannelPerformanceData(formDatafilter, { block: block })
            )
          : block.component == "ColumnLineChart"
          ? dispatch(getColumnLineData(formDatafilter, { block: block }))
          : "";
      });
    }
    setFromDateChange(filterData["from_date"]);
    setToDateChange(filterData["to_date"]);
    setTriggerRerender(true);
  };

  if (Object.keys(data).length > 0) {
    var filteredData = data;
  } else {
    var filteredData = defaultFilterData;
  }
  localStorage.setItem("report_filter", JSON.stringify(filteredData));

  /*fixed with help of sujan, check change in data type*/
  /*useEffect(() => {
    if (Array.isArray(data) && !data.length){
      setData(defaultFilterData)
    }

    if (typeof data === 'object' &&
    !Array.isArray(data) &&
    !data
    ){
      setData(defaultFilterData)
    }
    //context.updateAppData(filteredData);
    dispatch(setFilteredData(filteredData));
  },[JSON.stringify(filteredData), filteredData!=undefined]);*/

  const onChangeCache = (checked) => {
    inputControlsData.forEach((element, i) => {
      filteredData["by_pass_cache"] = checked === true ? "no" : "yes";
      Object.keys(params).map((el) => {
        filteredData[el] = params[el];
      });
      var dateFormat = element.format;

      if (
        datePersistVal != null &&
        Object.keys(params).length == 0 &&
        datePersistVal["component_type"] == element.component_type
      ) {
        if (
          (presetRanges == undefined &&
            element.component_type == "customrangepicker") ||
          element.component_type == "multiplecustomdatepicker"
        ) {
        } else if (
          (element.type == "daterangepicker" ||
            element.type == "monthrangepicker" ||
            element.type == "date") &&
          dateFormat === datePersistVal["dateFormat"]
        ) {
          var presetRanges = element.presetRanges;
          var additionalRanges = element.additionalPresetRanges;
          if (
            presetRanges == undefined &&
            datePersistVal["date_range_option"] == "Custom"
          ) {
            Object.keys(datePersistVal).map((el) => {
              filteredData[el] = datePersistVal[el];
            });
          } else if (
            presetRanges != undefined &&
            additionalRanges != undefined &&
            presetRanges.includes(datePersistVal["date_range_option"]) &&
            datePersistVal["additional_persist_label"] == "None"
          ) {
            Object.keys(datePersistVal).map((el) => {
              filteredData[el] = datePersistVal[el];
            });
          } else if (
            presetRanges != undefined &&
            additionalRanges != undefined &&
            presetRanges.includes(datePersistVal["date_range_option"])
          ) {
            Object.keys(datePersistVal).map((el) => {
              filteredData[el] = datePersistVal[el];
            });
          }
        }
      }
    });
    let formDatafilterCache = formDataAppend(filteredData);
    {
      layoutData?.layout_block?.map((block) =>
        block.component == "KeyMetrics"
          ? dispatch(getKeyMetrics(formDatafilterCache, { block: block }))
          : block.component == "SplineBar"
          ? dispatch(getTrendsData(formDatafilterCache, { block: block }))
          : block.component == "TrendBreakdown"
          ? dispatch(getTrendBreakdown(formDatafilterCache, { block: block }))
          : block.component == "DevicePerformance"
          ? dispatch(
              getDevicePerformance(formDatafilterCache, { block: block })
            )
          : block.component == "CollapsibleTable"
          ? dispatch(getNormalTable(formDatafilterCache, { block: block }))
          : block.component == "PerformanceReport"
          ? PerformanceReport(formDatafilterCache, { block: block })
          : block.component == "ColumnCharts"
          ? dispatch(getSessionData(formDatafilterCache, { block: block }))
          : block.component == "BarChart"
          ? dispatch(getBarGraphData(formDatafilterCache, { block: block }))
          : block.component == "DataTable"
          ? dispatch(getDataTable(formDatafilterCache, { block: block }))
          : block.component == "DataTableClient" ||
            block.component == "clientDataTable"
          ? dispatch(getDataTable(formDatafilterCache, { block: block }))
          : block.component == "StackedBar"
          ? dispatch(
              getStackedBarGraphData(formDatafilterCache, { block: block })
            )
          : block.component == "LineChart"
          ? dispatch(
              getRevenueRetentionData(formDatafilterCache, { block: block })
            )
          : block.component == "Slider"
          ? dispatch(getSliderData(formDatafilterCache, { block: block }))
          : block.component == "StackedChart"
          ? dispatch(getBarGraphData(formDatafilterCache, { block: block }))
          : block.component == "PieChart"
          ? dispatch(getPieChartData(formDatafilterCache, { block: block }))
          : block.component == "DonutChart"
          ? dispatch(getDonutChartData(formDatafilterCache, { block: block }))
          : block.component == "StackAreaChart"
          ? dispatch(getAreaChartData(formDatafilterCache, { block: block }))
          : block.component == "ChannelPerformance"
          ? dispatch(
              getChannelPerformanceData(formDatafilterCache, { block: block })
            )
          : block.component == "HTMLRender"
          ? dispatch(getHTML(formDatafilterCache, { block: block }))
          : block.component == "CLVGoal"
          ? dispatch(getTableSliderData(formDatafilterCache, { block: block }))
          : block.component == "CampaignCollapsibleTable"
          ? dispatch(
              getDevicePerformance(formDatafilterCache, { block: block })
            )
          : block.component == "EditableDataTable"
          ? dispatch(getDataTable(formDatafilterCache, { block: block }))
          : block.component == "Slider"
          ? dispatch(getSliderData(formDatafilterCache, { block: block }))
          : block.component == "Scatter"
          ? dispatch(getScatterData(formDatafilterCache, { block: block }))
          : block.component == "SEOProjectStatus"
          ? dispatch(getSessionData(formDatafilterCache, { block: block }))
          : block.component == "CampaignPerformance"
          ? dispatch(
              getDevicePerformance(formDatafilterCache, { block: block })
            )
          : block.component == "PlacementPerformance"
          ? dispatch(
              getDevicePerformance(formDatafilterCache, { block: block })
            )
          : block.component == "HTMLRender"
          ? dispatch(getHTML(formDatafilterCache, { block: block }))
          : block.component == "NormalTableWithColGroup"
          ? dispatch(getDataFromAPI(formDatafilterCache, { block: block }))
          : block.component == "CustomChannelPerformance"
          ? dispatch(
              getChannelPerformanceData(formDatafilterCache, { block: block })
            )
          : block.component == "ColumnLineChart"
          ? dispatch(getColumnLineData(formDatafilterCache, { block: block }))
          : ""
      );
    }
  };

  const cstyle = {
    padding: "8px 0",
  };

  const blockLookup = new Map();
  const blockIds = [];
  for (let i = 0; i < dataLayout.length; i++) {
    const { block_id, component, ...rest } = dataLayout[i];
    blockLookup.set(`${block_id}_${component}`, {
      block_id,
      component,
      ...rest,
    });
    blockIds.push(block_id);
  }

  const new_rows = [];
  for (let i = 0; i < controlLayout.length; i++) {
    const { cols, ...rest } = controlLayout[i];
    const new_cols = [];
    for (let j = 0; j < cols.length; j++) {
      const { block_id, component, ...colRest } = cols[j];
      new_cols.push({
        ...colRest,
        key: blockLookup.get(`${block_id}_${component}`) || {},
      });
    }
    new_rows.push({ ...rest, cols: new_cols });
  }

  function generatePdf() {
    dispatch({
      type: ACTIONS_TYPES.REQUEST_EXPORT,
    });

    window.scrollTo(0, 0);
    const reportName = report_key.replace(/[^a-zA-Z0-9\s]/g, " ");
    const divToPrint = document.querySelector("#divToPrint");
    const width = divToPrint.offsetWidth;
    const height = divToPrint.offsetHeight;
    const margin = 40; // Adjust the margin value as needed
    const padding = 40;
    const opt = {
      filename: `${report_key}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        scale: 3, // Increase scale value for better quality
        logging: true,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        useCORS: true,
        windowWidth: width + margin * 10,
        windowHeight: height + margin * 10,
      },
      jsPDF: {
        unit: "px",
        format: [width + 2 * margin + 200, height + margin * 6],
        orientation: "portrait",
      },
    };

    const additionalContent = document.createElement("div");
    additionalContent.classList.add("pdf-extra-info");
    additionalContent.innerHTML = `
			<img src="https://agital.com/wp-content/uploads/2023/06/logo-gradient-green.svg" alt="My Image">
    			<h2>${account_name}</h2>
    			<h4>${reportsName}</h4>

  		`;

    const clonedDivToPrint = divToPrint.cloneNode(true);
    clonedDivToPrint.style.backgroundColor = "#f0f2f5";
    clonedDivToPrint.style.padding = `${padding}px`;
    clonedDivToPrint.insertBefore(
      additionalContent,
      clonedDivToPrint.firstChild
    );

    html2pdf()
      .set(opt)
      .from(clonedDivToPrint)
      .save()
      .then(() => {
        dispatch({
          type: ACTIONS_TYPES.SUCCESS_EXPORT,
        });
      })
      .catch((error) => {
        console.error("Failed to generate PDF:", error);
      });
  }
  //check for date range
  const mobileFilter = () => {
    setfilterDisplay(!filterDisplay);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default form submission behavior
    }
  };
  return (
    <>
      <Spin
        style={{ height: "50%", marginTop: "20%" }}
        size="large"
        spinning={isLoading}
      >
        {layoutData.access_status == 403 ? (
          <Redirect to="/access-denied" />
        ) : (
          <></>
        )}
        {layoutData.access_status == undefined &&
        layoutData.message == undefined ? (
          <></>
        ) : (
          <>
            <style dangerouslySetInnerHTML={{ __html: custom_report_css }} />
            {layoutData.authorized_status == 401 ? (
              <Unauthorized
                message={layoutData.message}
                className={report_key}
              />
            ) : (
              <>
                <Form
                  form={form}
                  name="advanced_search"
                  className="ant-advanced-search-form"
                  onFinish={onFilterChanges}
                  onKeyDown={handleKeyPress}
                >
                  <Row>
                    <Col
                      lg={{ span: 20, order: 1 }}
                      xs={{
                        span: 24,
                        order: 2,
                      }}
                    >
                      <div
                        className={`input-control ${
                          filterDisplay
                            ? "show-mobile-filter"
                            : "hide-mobile-filter"
                        } `}
                      >
                        {inputControlsData.map((block) => (
                          <>
                            {block?.type != "hidden" && (
                              <div className="Report-V2-filter-option">
                                {InputControlComponent(
                                  block,
                                  account_id_data,
                                  clientData,
                                  report_key,
                                  filteredData,
                                  form
                                )}
                              </div>
                            )}
                          </>
                        ))}
                        {
                          <div className="Report-V2-filter-option Report-V2-filter-option-submit">
                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                onSubmit={(e) => e.preventDefault()}
                              >
                                Apply
                              </Button>
                            </Form.Item>
                          </div>
                        }
                        {/* cache button */}
                      </div>
                    </Col>
                    <Col
                      xs={{
                        span: 24,
                        order: 1,
                      }}
                      sm={{
                        span: 24,
                        order: 1,
                      }}
                      lg={{
                        span: 4,
                        order: 2,
                      }}
                      className="report-v2-filter-buttons-wrapper"
                    >
                      <div className="report-v2-filter-buttons">
                        <Button
                          className="mobile-filter-btn"
                          onClick={mobileFilter}
                        >
                          <FaFilter />
                        </Button>
                        <Tooltip title="Data Cache">
                          <Switch
                            size="small"
                            defaultChecked
                            onChange={onChangeCache}
                          />
                        </Tooltip>
                        <Help data={layoutData} />
                        <GlobalExport pdf_data={generatePdf} />
                        <CommentSettings
                          currentBlockId={blockIds}
                          defaultFilterData={defaultFilterData}
                          blockIdParam={blockIds}
                          account_id={account_id}
                          report_name={report_name}
                          triggerRerender={triggerRerender}
                          onFilterChanges={onFilterChanges}
                          fromDateChange={fromDateChange}
                          toDateChange={toDateChange}
                          setTriggerRerender={setTriggerRerender}
                          accountDataSources={accountDataSources}
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* block content starts here */}
                  <div id="divToPrint">
                    {new_rows.map((row, index) => (
                      <>
                        {reportLayouts &&
                          reportLayouts.layouts &&
                          Array.isArray(reportLayouts.layouts.input_controls) &&
                          reportLayouts.layouts.input_controls.some(
                            (item) =>
                              item &&
                              (item.type === "daterangepicker" ||
                                item.label === "Date")
                          ) &&
                          "Custom" &&
                          custom_annotation_report_show &&
                          blockIds[index] === 825 && (
                            <div
                              className={
                                "report-v2-panel-annotation" +
                                " " +
                                report_name +
                                "-" +
                                row.id
                              }
                              id={"annotation" + row.id}
                              block-id={blockIds[index]}
                            >
                              <ReportComments
                                currentBlockId={blockIds[index]}
                                defaultFilterData={defaultFilterData}
                                data={data}
                                blockIdParam={blockIds}
                                account_id={account_id}
                                report_name={report_name}
                                triggerRerender={triggerRerender}
                                onFilterChanges={onFilterChanges}
                                fromDateChange={fromDateChange}
                                toDateChange={toDateChange}
                                setTriggerRerender={setTriggerRerender}
                                accountDataSources={accountDataSources}
                                atValues={atValues}
                                account_name={account_name}
                              />
                            </div>
                          )}
                        <div id={row.id}>
                          {row.collapse != undefined &&
                          row.collapse == "true" &&
                          !isCustomer() &&
                          !isNonManagedCustomer() ? (
                            <div className="report-v2-panel">
                              <Collapse
                                defaultActiveKey={["2"]}
                                expandIcon={({ isActive }) => (
                                  <PlusOutlined rotate={isActive ? 45 : 0} />
                                )}
                              >
                                <Panel header="SUPPLEMENTAL DATA" key="1">
                                  <Row
                                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                    key={row.id + "-cols"}
                                  >
                                    {row.cols.map((col) => (
                                      <Col
                                        className="gutter-row"
                                        span={parseInt(col?.span)}
                                        style={{ display: "flex" }}
                                        id={col.id}
                                      >
                                        <div
                                          className="report-v2-panel-wrapper"
                                          style={{ width: "100%" }}
                                        >
                                          {row?.top_left_information_data !=
                                          null ? (
                                            <div
                                              className="top-left-text"
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  row?.top_left_information_data,
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}

                                          {row?.top_right_information_data !=
                                          null ? (
                                            <div
                                              className="top-right-text"
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  row?.top_right_information_data,
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}

                                          <div className="report-v2-panel-heading">
                                            <h2>
                                              {col?.title}
                                              {col?.tooltip !== undefined &&
                                              col?.tooltip !== "" ? (
                                                <Tooltip
                                                  title={
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html: col?.tooltip,
                                                      }}
                                                    />
                                                  }
                                                  overlayClassName="heading-tooltip"
                                                >
                                                  <span>
                                                    <FaInfoCircle />
                                                  </span>
                                                </Tooltip>
                                              ) : col?.tooltip1 !== undefined &&
                                                ad_spend_status == 0 ? (
                                                <Tooltip
                                                  title={
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html: col?.tooltip1,
                                                      }}
                                                    />
                                                  }
                                                  overlayClassName="heading-tooltip"
                                                >
                                                  <span>
                                                    <FaInfoCircle />
                                                  </span>
                                                </Tooltip>
                                              ) : col?.tooltip2 !== undefined &&
                                                ad_spend_status == 1 ? (
                                                <Tooltip
                                                  title={
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html: col?.tooltip2,
                                                      }}
                                                    />
                                                  }
                                                  overlayClassName="heading-tooltip"
                                                >
                                                  <span>
                                                    <FaInfoCircle />
                                                  </span>
                                                </Tooltip>
                                              ) : (
                                                ""
                                              )}
                                            </h2>
                                          </div>
                                          <div className="report-v2-panel-body">
                                            {BlockComponent(
                                              col.key,
                                              account_id_data,
                                              clientData,
                                              filteredData
                                            )}
                                          </div>
                                        </div>
                                      </Col>
                                    ))}
                                  </Row>
                                </Panel>
                              </Collapse>
                            </div>
                          ) : (
                            <div className="report-v2-panel">
                              <Row
                                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                key={row.id + "-cols"}
                              >
                                {row?.hidden != "true" &&
                                  row.cols.map((col) => (
                                    <Col
                                      className="gutter-row"
                                      span={parseInt(col?.span)}
                                      style={{ display: "flex" }}
                                      id={col.id}
                                    >
                                      <div
                                        className="report-v2-panel-wrapper"
                                        style={{ width: "100%" }}
                                      >
                                        {row?.top_left_information_data !=
                                        null ? (
                                          <div
                                            className="top-left-text"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                row?.top_left_information_data,
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}

                                        {row?.top_right_information_data !=
                                        null ? (
                                          <div
                                            className="top-right-text"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                row?.top_right_information_data,
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}

                                        <div className="report-v2-panel-heading">
                                          <h2>
                                            {col?.title}
                                            {col?.tooltip !== undefined &&
                                            col?.tooltip !== "" ? (
                                              <Tooltip
                                                title={
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html: col?.tooltip,
                                                    }}
                                                  />
                                                }
                                                overlayClassName="heading-tooltip"
                                              >
                                                <span>
                                                  <FaInfoCircle />
                                                </span>
                                              </Tooltip>
                                            ) : col?.tooltip1 !== undefined &&
                                              ad_spend_status == 0 ? (
                                              <Tooltip
                                                title={
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html: col?.tooltip1,
                                                    }}
                                                  />
                                                }
                                                overlayClassName="heading-tooltip"
                                              >
                                                <span>
                                                  <FaInfoCircle />
                                                </span>
                                              </Tooltip>
                                            ) : col?.tooltip2 !== undefined &&
                                              ad_spend_status == 1 ? (
                                              <Tooltip
                                                title={
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html: col?.tooltip2,
                                                    }}
                                                  />
                                                }
                                                overlayClassName="heading-tooltip"
                                              >
                                                <span>
                                                  <FaInfoCircle />
                                                </span>
                                              </Tooltip>
                                            ) : (
                                              ""
                                            )}
                                          </h2>
                                        </div>
                                        <div className="report-v2-panel-body">
                                          {BlockComponent(
                                            col.key,
                                            account_id_data,
                                            clientData,
                                            filteredData
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  ))}
                              </Row>
                            </div>
                          )}
                        </div>
                        {reportLayouts &&
                          reportLayouts.layouts &&
                          Array.isArray(reportLayouts.layouts.input_controls) &&
                          reportLayouts.layouts.input_controls.some(
                            (item) =>
                              item &&
                              (item.type === "daterangepicker" ||
                                item.label === "Date")
                          ) &&
                          (reportLayouts.layouts.data.layout[0].report_view ===
                            "Customer" ||
                            (blockIds[index] != 825 &&
                              "Custom" &&
                              custom_annotation_report_show) ||
                            reportLayouts.layouts.data.layout[0].report_view ===
                              "Customer Internal") && (
                            <div
                              className={
                                "report-v2-panel-annotation" +
                                " " +
                                report_name +
                                "-" +
                                row.id
                              }
                              id={"annotation" + row.id}
                              block-id={blockIds[index]}
                            >
                              <ReportComments
                                currentBlockId={blockIds[index]}
                                defaultFilterData={defaultFilterData}
                                data={data}
                                blockIdParam={blockIds}
                                account_id={account_id}
                                report_name={report_name}
                                triggerRerender={triggerRerender}
                                onFilterChanges={onFilterChanges}
                                fromDateChange={fromDateChange}
                                toDateChange={toDateChange}
                                setTriggerRerender={setTriggerRerender}
                                accountDataSources={accountDataSources}
                                atValues={atValues}
                                account_name={account_name}
                              />
                            </div>
                          )}
                      </>
                    ))}
                  </div>
                </Form>
                <BackTop>
                  <div>
                    <FaAngleUp />
                  </div>
                </BackTop>
              </>
            )}
          </>
        )}
      </Spin>
    </>
  );
};
export default Layout;
